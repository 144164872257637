import { Box, Stack } from '@mui/material';
import React from 'react';
import { blockTypeData } from './constants';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { ButtonSlider } from '@v2/components/ui/ButtonSlider';
import { AudienceBlockProps } from './types';
import {
  IconBoxStyled,
  IconStyled,
  PlainIconStyled,
  WrapperBoxStyled,
} from './styles';

export const AudienceBlock = ({
  value = [],
  onChange,
  type,
  label,
  options,
  multiple = false,
  highlightFirstItem = false,
  ...props
}: AudienceBlockProps) => {
  const [isEditing, setIsEditing] = React.useState(false);

  return isEditing ? (
    <ButtonSlider
      options={options}
      value={value}
      onChange={(v: string | number[]) => {
        onChange(v);
        setIsEditing(false);
      }}
      multiple={multiple}
      highlightFirstItem={highlightFirstItem}
    />
  ) : (
    <WrapperBoxStyled
      alignItems="center"
      direction="row"
      spacing={2}
      data-testid={`audience-block-${type}`}
      {...props}
    >
      <IconBoxStyled display="flex" alignItems="center">
        <PlainIconStyled name={blockTypeData[type].icon} />
      </IconBoxStyled>
      <Box flex={1}>{label(value)}</Box>
      <Stack
        data-testid={`audience-block-${type}-edit`}
        alignItems="center"
        onClick={() => setIsEditing(true)}
      >
        <IconStyled icon={faPen} />
      </Stack>
    </WrapperBoxStyled>
  );
};
