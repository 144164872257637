import { Box, styled } from '@mui/material';
import { ButtonCard } from '@v2/components/campaign/ButtonCard';

export const QuickstartCardStyled = styled(ButtonCard)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[9]}`,
  boxShadow: 'none',
}));

export const ContentStyled = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 4, 8),
}));

export const QuickstartCardInnerStyled = styled(ContentStyled)({
  opacity: 0.4,
});
