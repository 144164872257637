import { useGroups } from '@hooks/apis/groups';
import { getCampaignId, getGroupType } from './utils';
import { useFlags } from '@hooks/flags';
import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';
import { LineItem } from '@local-types';

export const useBWOverride = (adGroup: LineItem) => {
  const campaignId = getCampaignId(adGroup);
  const groupType = getGroupType(adGroup);

  const { v2AdgroupsLineItems } = useLDFlags();
  const { flags } = useFlags();
  const isArchivingEnabled = flags.USER_CAN_ARCHIVE;
  const v2Endpoint = v2AdgroupsLineItems || isArchivingEnabled;

  const { update: updateGroup } = useGroups(groupType, campaignId, {
    params: {
      v1: !v2Endpoint,
      queryProps: {
        sync: 1,
      },
    },
  });

  const handleBWOverride = async () => {
    await updateGroup({
      id: adGroup.id,
      params: { targeting_override: 1 },
    });
  };

  return {
    handleBWOverride,
  };
};
