import React, { useMemo } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { DataGrid } from '@v2/components/ui/DataGrid/DataGrid';
import { defaultTheme_v2 } from '@themes/default_v2';
import { DateCellRenderer } from '@v2/components/ui/DataGrid/renderers/DateCellRenderer';
import { DotNotationValueGetter } from '@v2/components/ui/DataGrid/DotNotationValueGetter';
import { CurrencyCellRenderer } from '@v2/components/ui/DataGrid/renderers/CurrencyCellRenderer';
import { GridColDef } from '@mui/x-data-grid-pro';
import { useCreativeLibrary } from './CreativeLibraryContext';
import { CreativeLibraryTitleCellRenderer } from './renderers/CreativeLibraryTitleCellRenderer';
import { CreativeLibraryAdGroupCellRenderer } from './renderers/CreativeLibraryAdGroupCellRenderer';
import { CreativeLibraryAssignCellRenderer } from './renderers/CreativeLibraryAssignCellRenderer';
import { CreativeQCStatusCellRenderer } from '@components/Creatives/renderers/CreativeQCStatusCellRenderer';
import { Scopes } from '@constants/scopes';
import { usePermissions } from '@components/hooks/permissions';

export const CreativeLibraryTableV2 = () => {
  const { isLoading, creatives, count, setSelected, selected } =
    useCreativeLibrary();
  const { hasPermission } = usePermissions();
  const showQCFunctionality = hasPermission([Scopes.CAN_VIEW_QC]);

  const columns = useMemo(
    () =>
      [
        {
          field: 'id',
          headerName: 'ID',
          width: 60,
          resizable: false,
          hideable: false,
        },
        {
          field: 'name',
          headerName: 'Creative Title',
          flex: 1,
          hideable: false,
          renderCell: CreativeLibraryTitleCellRenderer,
        },
        {
          field: 'adGroup',
          headerName: 'Ad Groups',
          flex: 1,
          renderCell: CreativeLibraryAdGroupCellRenderer,
          resizable: false,
          hideable: false,
        },
        {
          field: 'stats.total_spend',
          headerName: 'Spend',
          valueGetter: DotNotationValueGetter,
          renderCell: CurrencyCellRenderer,
          minWidth: 90,
          align: 'right',
          resizable: false,
          hideable: false,
        },
        {
          field: 'stats.visit_count',
          headerName: 'Visits',
          valueGetter: DotNotationValueGetter,
          minWidth: 90,
          align: 'right',
          resizable: false,
          hideable: false,
        },
        {
          field: 'stats.cost_per_visit',
          headerName: 'Cost Per Visit',
          valueGetter: DotNotationValueGetter,
          renderCell: CurrencyCellRenderer,
          minWidth: 90,
          align: 'right',
          resizable: false,
          hideable: false,
        },
        {
          field: 'media_upload_date',
          headerName: 'Uploaded',
          renderCell: DateCellRenderer,
          minWidth: 120,
          resizable: false,
          hideable: false,
        },
        showQCFunctionality && {
          field: 'active',
          headerName: '',
          renderCell: CreativeQCStatusCellRenderer,
          width: 60,
          resizable: false,
          hideable: false,
        },
        {
          field: 'assigned',
          headerName: 'Assign',
          renderCell: CreativeLibraryAssignCellRenderer,
          width: 120,
          resizable: false,
          hideable: false,
        },
      ].filter(Boolean) as GridColDef[],
    [],
  );

  return (
    <ThemeProvider theme={defaultTheme_v2}>
      <DataGrid
        checkboxSelection
        loading={isLoading}
        rows={creatives}
        rowCount={count}
        columns={columns}
        disableRowSelectionOnClick
        rowSelectionModel={selected}
        onRowSelectionModelChange={(newSelections: number[]) => {
          setSelected(newSelections);
        }}
      />
    </ThemeProvider>
  );
};
