import { Audience } from '@local-types/audience';
import {
  useLoadInfinite,
  UseLoadInfiniteConfiguration,
  UseLoadInfiniteOptions,
} from './general';

export const useGetAudiences = (
  options?: UseLoadInfiniteOptions,
  configuration?: UseLoadInfiniteConfiguration<Audience>
) => {
  return useLoadInfinite<Audience>('audiences/', options, configuration);
};

export const useGetAllAudiences = (
  configuration?: UseLoadInfiniteConfiguration<Audience>
) => {
  const { items: audiencesOther } = useGetAudiences(
    {
      params: {
        queryProps: {
          type_exclude: 'retargeting',
        },
      },
    },
    configuration
  );

  const { items: audiencesRetargeting } = useGetAudiences(
    {
      params: {
        queryProps: {
          type: 'retargeting',
        },
      },
    },
    configuration
  );

  return {
    items: [...audiencesOther, ...audiencesRetargeting],
  };
};
