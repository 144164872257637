import { faArrowLeft, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Stack } from '@mui/material';
import React from 'react';
import { Info } from '../../Info';
import { AdvancedBoxProps } from './types';
import {
  AdvancedBoxStyled,
  BackIconStyled,
  ContentWrapperStyled,
  DividerStyled,
  HeaderWrapperStyled,
  InfoBoxCloseStyled,
  InfoBoxInfoIconStyled,
  InfoBoxStyled,
  OverlayBoxStyled,
  WrapperBoxStyled,
} from './styles';

export const AdvancedBox = ({
  header,
  children,
  onClose,
  onInfoClose,
  info,
  error,
  ...props
}: AdvancedBoxProps) => {
  return (
    <OverlayBoxStyled>
      <WrapperBoxStyled {...props}>
        {error && (
          <Info data-testid="advanced-box-error" type="error">
            {error}
          </Info>
        )}
        {info && (
          <InfoBoxStyled
            data-testid="advanced-box-info"
            sx={{
              backgroundColor: 'blue.10',
            }}
          >
            <Stack alignItems="center" direction="row" spacing={1.5} flex={1}>
              <InfoBoxInfoIconStyled
                sx={{
                  color: 'blue.1',
                }}
                icon={faInfoCircle}
              />
              {info}
            </Stack>
            <IconButton onClick={onInfoClose}>
              <InfoBoxCloseStyled icon={faClose} />
            </IconButton>
          </InfoBoxStyled>
        )}
        <AdvancedBoxStyled flex={1} spacing={4}>
          <Stack
            justifyItems="center"
            alignItems="center"
            direction="row"
            // spacing={3}
          >
            <BackIconStyled onClick={onClose}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </BackIconStyled>
            <DividerStyled flexItem orientation="vertical" />
            <HeaderWrapperStyled>{header}</HeaderWrapperStyled>
          </Stack>
          <ContentWrapperStyled>{children}</ContentWrapperStyled>
        </AdvancedBoxStyled>
      </WrapperBoxStyled>
    </OverlayBoxStyled>
  );
};
