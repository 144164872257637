import { useRef } from "react";
import { Filters, useLookerFiltersContext } from "@providers/LookerFiltersContext";
import { LookerEmbedDashboard, LookerEmbedEvent } from "@looker/embed-sdk";
import { useFlags } from 'launchdarkly-react-client-sdk';

const useLookerFilters = (id: string) => {
  const dashboardRef = useRef<null | LookerEmbedDashboard>(null);
  const { setFilters, updateDashboardRefs } = useLookerFiltersContext()
  const { reportsStickyFilters } = useFlags();

  const setupDashboard = (dashboard: LookerEmbedDashboard) => {
    if (!reportsStickyFilters) return
    dashboardRef.current = dashboard
    if (id && updateDashboardRefs && dashboardRef.current) {
      updateDashboardRefs(id, dashboardRef.current)
    }
  }

  const handleFiltersChanged = (event: LookerEmbedEvent) => {
    if (!reportsStickyFilters) return
    if (setFilters) {
      setFilters((prev: Filters) => {
        const { dashboard: { dashboard_filters } } = event
        const newFilters = ({
          ...prev,
        })

        if (dashboard_filters['Date Filter'] !== undefined) {
          newFilters['Date Filter'] = dashboard_filters['Date Filter']
        }

        if (dashboard_filters['Outcome'] !== undefined) {
          newFilters['Outcome'] = dashboard_filters['Outcome']
        }

        if (dashboard_filters['MMP View'] !== undefined) {
          newFilters['MMP View'] = dashboard_filters['MMP View']
        }

        const newAtttributionModel =
          dashboard_filters['Attribution Model Selector'] ||
          dashboard_filters['Attribution Model']

        if (newAtttributionModel !== undefined) {
          newFilters['Attribution Model Selector'] = newAtttributionModel;
          newFilters['Attribution Model'] = newAtttributionModel;
        }

        const newAtttributionWindow =
          dashboard_filters['Attribution Window Selector'] ||
          dashboard_filters['Attribution Window']

        if (newAtttributionWindow !== undefined) {
          newFilters['Attribution Window Selector'] = newAtttributionWindow;
          newFilters['Attribution Window'] = newAtttributionWindow;
        }

        const newCampaign =
          dashboard_filters.Campaign ||
          dashboard_filters['Campaign Name']

        if (newCampaign !== undefined) {
          newFilters.Campaign = newCampaign;
          newFilters['Campaign Name'] = newCampaign;
        }

        return newFilters
      });
    }
  }

  return { setupDashboard, handleFiltersChanged }
};

export default useLookerFilters;
