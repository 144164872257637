import { Box, Stack, Typography, styled } from '@mui/material';
import { PropTypes } from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';
import { OptionsAccordion } from '../../../OptionsAccordion/OptionsAccordion';
import { fields } from '../constants';
import { useBidStrategy } from './hooks/useBidStrategy';
import {
  AdditionalOptionsTypographyStyled,
  AdvancedSectionIconStyled,
  DividerStyled,
  DividerTypographyStyled,
  SectionBoxStyled,
  SectionInnerBoxStyled,
} from '../styles';
import { BID_STRATEGIES } from './constants';
import { unitsToLabel } from '../../FrequencyCapFieldsGroup';
import {
  convertTimeToExtended,
  toDaysList,
  convertTimeRangeToAmPM,
} from '../../DaypartingFieldsGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPen,
  faSquareArrowUpRight,
} from '@fortawesome/pro-regular-svg-icons';
import { DataList } from '../DataList';
import {
  SectionInfo,
  sectionInfoType,
} from '../../../../../components/SectionInfo';

const PenStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
  cursor: 'pointer',
  padding: 0,
  color: theme.palette.grey[5],
}));

export const BidStrategy = ({ onAdvanced, info }) => {
  const {
    control,
    advanced,
    Copy,
    shouldShowBidStrategy,
    frequencyCap,
    dayparting,
    isPacingAllowed,
    isPacingEnabled,
    pacingStrings,
    bidStrategyOptions,
    removeEvent,
  } = useBidStrategy({
    onAdvanced,
  });

  return (
    <SectionBoxStyled>
      <DividerStyled textAlign="left">
        <DividerTypographyStyled>
          {Copy.bidStrategyTitle}
        </DividerTypographyStyled>
      </DividerStyled>
      <SectionInnerBoxStyled>
        {shouldShowBidStrategy && (
          <>
            <SectionInfo infoBlocks={info} />
            <Controller
              name={fields.adGroupBidStrategy.path}
              control={control}
              render={({ field }) => (
                <OptionsAccordion
                  {...field}
                  trackingIsSetUp
                  options={bidStrategyOptions}
                  headerTitle={Copy.bidStrategyHeader}
                  onChange={(_, option) => {
                    field.onChange(option.value);

                    if (option.label !== BID_STRATEGIES.MAX_OUTCOMES) {
                      removeEvent();
                    }
                  }}
                />
              )}
            />
          </>
        )}
        <Stack gap={1}>
          <AdditionalOptionsTypographyStyled onClick={() => advanced()}>
            <AdvancedSectionIconStyled icon={faSquareArrowUpRight} />
            {Copy.advancedDeliveryOptionsLabel}
          </AdditionalOptionsTypographyStyled>
          {(!!frequencyCap.length || !!dayparting.length) && (
            <DataList
              title={Copy.frequencyCapLabel}
              data={frequencyCap}
              render={(c, idx) => (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="body2">
                    {c.impressions} ads every {c.duration}{' '}
                    {unitsToLabel({
                      unit: c.fctype,
                      duration: c.duration,
                    })}
                  </Typography>
                  <PenStyled
                    role="button"
                    onClick={() => {
                      advanced(idx, 'frequencyCap');
                    }}
                    icon={faPen}
                  />
                </Stack>
              )}
            />
          )}
          {!!dayparting.length && (
            <DataList
              title="Dayparting:"
              data={dayparting}
              render={(d, idx) => (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="body2">
                    {toDaysList(d.days)}{' '}
                    {convertTimeRangeToAmPM(convertTimeToExtended(d.time))}
                  </Typography>
                  <PenStyled
                    role="button"
                    onClick={() => {
                      advanced(idx, 'dayparting');
                    }}
                    icon={faPen}
                  />
                </Stack>
              )}
            />
          )}
          {isPacingAllowed && isPacingEnabled && (
            <DataList
              title="Pacing:"
              data={pacingStrings}
              render={d => (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="body2">{d.join(', ')}</Typography>
                  <PenStyled
                    role="button"
                    onClick={() => advanced()}
                    icon={faPen}
                  />
                </Stack>
              )}
              singleSubField
            />
          )}
        </Stack>
      </SectionInnerBoxStyled>
    </SectionBoxStyled>
  );
};

BidStrategy.propTypes = {
  ...Box.propTypes,
  onAdvanced: PropTypes.func,
  info: PropTypes.arrayOf(sectionInfoType),
};
