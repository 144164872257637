import React from 'react';
import { createRoot } from 'react-dom/client';
import moment from 'moment-timezone';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'

import Root from './components/Root';
import './styles/styles.scss'; // Yep, that's right. You can import SASS/CSS files too! Webpack will run the associated loader and plug this into the page.
import 'fontsource-noto-sans-jp';
import './favicon.ico'; // Tell webpack to load favicon.ico
import { LicenseInfo } from '@mui/x-license';

LicenseInfo.setLicenseKey(
  '9bbd6ba09141875dfec0642e693d1505Tz0xMDU0MTIsRT0xNzY4MDAzMTk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=',
);

(async () => {
  if (!process.env.LAUNCH_DARKLY_CLIENT_ID) {
    throw new Error('LAUNCH_DARKLY_CLIENT_ID must be defined');
  }

  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.LAUNCH_DARKLY_CLIENT_ID
  });
  // Set EST to default timezone
  moment.tz.setDefault('America/New_York');

  const container: HTMLElement | null = document.getElementById('app');
  if (container) {
    const root = createRoot(container);
    root.render(
      React.createElement(LDProvider, null,
        React.createElement(Root)
      )
    );
  } else {
    console.error("Failed to find the 'app' element");
  }

})();
