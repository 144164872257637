import { createTheme } from '@mui/material/styles';

// import customized compoetent styles
import { alertStyles } from './components/alert.styles';
import { buttonStyles } from './components/button.styles';
import { cssBaselineStyles } from './components/cssBaseline.styles';
import { formControlLabelStyles } from './components/formControlLabel.styles';
import { sliderStyles } from './components/slider.styles';
import { switchStyles } from './components/switch.styles';
import { selectStyles } from './components/select.styles';
import { textFieldStyles } from './components/textField.styles';
import { toggleButtonStyles } from './components/toggleButton.styles';

export const padmanTheme_v2 = createTheme({
  palette: {
    text: {
      primary: '#13171A',
      overlay: '#FFFFFF',
    },
    mode: 'light',
    theme: 'padmanTheme_v2',
    primary: {
      main: '#069DE0',
      light: '#068BC6',
      dark: '#057CB1',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#178CCC',
    },
    common: {
      black: '#13171A',
      white: '#FFFFFF',
      offWhite: '#FAFAFA',
    },
    success: {
      main: '#0FBF84',
      light: '#0CA672',
      dark: '#088C60',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#FF6577',
      light: '#EB4F62',
      dark: '#D24D5D',
      contrastText: '#FFFFFF',
    },
    tip: {
      main: '#FB9933',
      light: '#FBECB4',
      dark: '#2D3940',
      contrastText: '#FFFFFF',
    },
    green: {
      1: '#02402B',
      2: '#04593D',
      3: '#06734E',
      4: '#088C60',
      5: '#0CA672',
      6: '#05AC3F',
      7: '#29CC95',
      8: '#57D9AD',
      9: '#8AE5C7',
      10: '#C2F2E2',
    },
    grey: {
      1: '#2D3940',
      2: '#435159',
      3: '#5C6B73',
      4: '#808080',
      5: '#8B979E',
      6: '#A1ACB2',
      7: '#B0BABF',
      8: '#C0C8CC',
      9: '#D2D6D9',
      10: '#DFE3E5',
      11: '#EDF1F2',
      12: '#F7F9FA',
    },
    blue: {
      1: '#0E5880',
      2: '#116999',
      3: '#147BB2',
      4: '#178CCC',
      5: '#1A9EE5',
      6: '#1DA1F2',
      7: '#4DC0FF',
      8: '#73CDFF',
      9: '#99DBFF',
      10: '#BFE8FF',
      11: '#D9F1FF',
      12: '#000024',
    },
    red: {
      1: '#FF003D',
      2: '#FF6577',
      3: '#FFBFC7',
      4: '#AC0029',
      5: '#FFDEE2',
    },
    chart: {
      1: '#1A6ED4',
      2: '#5744B2',
      3: '#DB4C4C',
      4: '#F27799',
      5: '#FB9933',
      6: '#FEC827',
      7: '#7AD426',
    },
    background: {
      paper: '#FFFFFF',
    },
  },
  shape: {
    borderRadius: 8,
  },
  mixins: {},
  typography: {
    fontFamily: 'PeacockSans, Noto Sans JP, Helvetica, Arial, sans-serif',
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 700,
    fontWeightBold: 900,
    h1: {
      fontSize: '2.5rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '1.875rem',
      fontWeight: 700,
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.375rem',
      fontWeight: 700,
    },
    h5: {
      fontSize: '0.875rem',
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: '1.5rem',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    body2: {
      fontSize: '0.875rem',
      fontWeight: 400,
    },
    button: {
      textTransform: 'none',
      fontWeight: 700,
    },
    caption: {
      fontSize: '0.75rem',
    },
  },
  components: {
    MuiAlert: alertStyles,
    MuiButton: buttonStyles,
    MuiCssBaseline: cssBaselineStyles,
    MuiFormControlLabel: formControlLabelStyles,
    MuiSlider: sliderStyles,
    MuiSwitch: switchStyles,
    MuiSelect: selectStyles,
    MuiTextField: textFieldStyles,
    MuiToggleButton: toggleButtonStyles,
  },
  blueHoverShadow:
    '0px 5px 5px -3px rgba(74, 172, 226, 0.29), 0px 3px 14px 2px rgba(74, 172, 226, 0.23), 0px 8px 10px 1px rgba(74, 172, 226, 0.26)',
});
