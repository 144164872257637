import { useFormContext } from 'react-hook-form';
import { fields } from '../../constants';

export const useAudience = ({
  campaignName,
  isDisplay,
}: {
  campaignName: string;
  isDisplay: boolean;
}) => {
  const { control, watch } = useFormContext();

  const [audience] = watch([fields.advancedAudience.path]);

  return {
    audience: isDisplay
      ? [{ audience_name: campaignName }, ...audience]
      : audience,
    control,
  };
};
