import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';
import { useCreatives } from '@apis/creatives';
import { Creative } from '@local-types';

export interface CreativesTableContextProviderProps {
  children: React.ReactNode;
}

export interface CreativesTableContextType
  extends Omit<CreativesTableContextProviderProps, 'children'> {
  isLoading: boolean;
  isValidating: boolean;
  count: number;
  hasMore: boolean;
  creatives: Creative[];
  handleArchiveUnarchiveCreative: (creative: Creative) => void;
  setSize: Dispatch<SetStateAction<number>>;
}

export const CreativesTableContext = createContext<CreativesTableContextType>(
  {
    isLoading: false,
    isValidating: false,
    count: 0,
    creatives: [],
    hasMore: false,
    setSize: () => {},
    handleArchiveUnarchiveCreative: () => {},
  },
);

export const CreativesTableContextProvider = ({
  children,
  ...rest
}: CreativesTableContextProviderProps) => {
  const {
    creatives,
    count = 0,
    isLoading,
    isValidating,
    archiveTrigger,
    setSize,
    hasMore,
  } = useCreatives({
    params: {
      expand: 'lineitem_set,stats_caches_by_lineitem_id',
    },
    options: {
      keepPreviousData: true,
    },
  });

  const handleArchiveUnarchiveCreative = async (creative: Creative) => {
    await archiveTrigger({
      id: creative.id as never,
      isArchiving: !creative.archived,
    });
  };

  return (
    <CreativesTableContext.Provider
      value={{
        ...rest,
        setSize,
        count,
        creatives,
        isLoading,
        hasMore,
        isValidating,
        handleArchiveUnarchiveCreative,
      }}
    >
      {children}
    </CreativesTableContext.Provider>
  );
};

export const useCreativesTable = () => {
  return useContext(CreativesTableContext);
};
