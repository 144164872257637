import React from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { generatePath } from 'react-router-dom';
import { CreativeAdGroupV1, LineItem } from '@local-types';
import { RoutePaths } from '@constants/routes';
import { DataGridLink } from '@v2/components/ui/DataGrid/DataGridLink';

export const CreativeAdGroupCellRenderer = (
  params: GridCellParams<CreativeAdGroupV1['creative_object']>,
) => {
  const { lineitem_set, id: creativeId } = params.row;
  const campaign = (lineitem_set?.[0] as LineItem)?.campaign ?? 0;
  const campaignId =
    typeof campaign === 'string'
      ? campaign?.split('/campaigns/')[1]?.slice(0, -1)
      : (campaign as number);

  const adGroupName = (lineitem_set?.[0] as LineItem)?.name;

  if (!campaignId || !adGroupName || !creativeId) return <span>-</span>;

  return (
    <DataGridLink to={generatePath(RoutePaths.AD_GROUPS, { campaignId })}>
      {adGroupName}
    </DataGridLink>
  );
};
