import React from 'react';
import { Statuses } from '@components/containers/AdGroupsIndexPage/statuses';
import { generatePath } from 'react-router-dom';
import { RoutePaths } from '@constants/routes';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { useQuery, useWizardRedirect } from '@components/hooks';
import { Campaign } from '@local-types';
import { DataGridLink } from '@v2/components/ui/DataGrid/DataGridLink';

export const CampaignNameCellRenderer = (
  params: GridCellParams<Campaign, string>
) => {
  const { status, id } = params.row ?? {};
  const { isWizardEnabled } = useWizardRedirect();
  const urlQuery = useQuery();

  if (!id) {
    return null;
  }

  return (
    <DataGridLink
      to={
        status === Statuses.DRAFT
          ? isWizardEnabled
            ? {
                pathname: generatePath(RoutePaths.CAMPAIGN_REWORK, {
                  campaignId: id,
                }),
                search: urlQuery.toString(),
              }
            : generatePath(RoutePaths.OLD_CAMPAIGN_EDIT, {
                id,
              })
          : generatePath(RoutePaths.AD_GROUPS, {
              campaignId: id,
            })
      }
    >
      {params.value}
    </DataGridLink>
  );
};
