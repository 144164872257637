import React, { useMemo, useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import ListFilterForm from "@components/forms/ListFilterForm";
import { IFormValues, IItem } from '@components/forms/ListFilterForm/types';
import { useGetAdGroups } from '@apis/adGroups';

interface IAdGroupNameFilterProps {
  onSubmit: (params: Record<string, string>) => void;
  data: Record<string, string>;
}

const AdGroupNameFilter: React.FC<IAdGroupNameFilterProps> = ({
  onSubmit,
  data
}) => {
  const [searchValue, setSearchValue] = useState('');

  const queryParams = useMemo(() => {
    const params: Record<string, string> = {};
    
    if (searchValue) {
      params.name = searchValue;
    }
    
    if (data.campaignIds) {
      params.campaign_ids = data.campaignIds;
    }
    
    return params;
  }, [searchValue, data.campaignIds]);

  const { items: adGroups, isLoading, isValidating, setSize, hasMore } = useGetAdGroups(queryParams, {
    revalidateOnMount: true
  });

  const handleLoadMore = useCallback(() => {
    setSize((prev: number) => prev + 1);
  }, [setSize]);

  const formData = useMemo(() => {
    const selectedIds = data.adGroupIds ? data.adGroupIds?.split(',').map(Number) : [];

    return {
      items: adGroups?.map((adGroup: IItem) => ({
        id: adGroup.id,
        name: adGroup.name,
        checked: selectedIds.includes(adGroup.id),
      })),
      selectedIds,
      isLoading,
      isValidating,
      hasMore,
    };
  }, [adGroups, data.adGroupIds, isLoading, isValidating, hasMore]);

  const handleSearch = useCallback(
    debounce((value: string | undefined) => {
      setSearchValue(value || '');
    }, 300),
    []
  );

  const handleSubmit = (formData: IFormValues) => {
    onSubmit({
      adGroupIds: formData.selectedIds.join(',')
    });
  };

  return (
    <ListFilterForm
      fieldName="Ad Group Name"
      data={formData}
      onSearch={handleSearch}
      onSubmit={handleSubmit}
      onLoadMore={handleLoadMore}
    />
  );
};

export default AdGroupNameFilter;
