import React, { useEffect, useRef, useMemo, useCallback } from 'react';
import { useForm, useFieldArray, type Resolver } from 'react-hook-form';
import { FixedSizeList } from 'react-window';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Divider,
  GridProps,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Item } from './styled';
import { IListFilterFormProps, IFormValues } from './types/ListFilterFormProps.types';
import { Row } from './Row';
import { VirtualizedRow } from './components/VirtualizedRow';

const itemSchema = yup.object({
  id: yup.number().required(),
  name: yup.string().required(),
  checked: yup.boolean().required(),
});

const schema = yup.object({
  items: yup.array().of(itemSchema),
  searchValue: yup.string(),
  selectedIds: yup.array(yup.number())
});

const formResolver = yupResolver(schema as yup.AnyObjectSchema) as Resolver<IFormValues>;

const ROW_HEIGHT = 36;
const LIST_HEIGHT = 400;

const ListFilterForm: React.FC<IListFilterFormProps & Omit<GridProps, 'onSubmit'>> = (props) => {
  const { fieldName, data, onSubmit, onSearch, onLoadMore, ...rest } = props;
  const isInitialized = useRef(false);
  const lastLoadedIndex = useRef(-1);

  const { control, handleSubmit, formState, register, watch, setValue } = useForm<IFormValues>({
    defaultValues: {
      items: [],
      searchValue: '',
      selectedIds: [],
    },
    resolver: formResolver
  });

  const { fields } = useFieldArray<IFormValues, 'items', 'internalId'>({
    control,
    name: "items",
    keyName: 'internalId',
  });

  const [searchValue, selectedIds] = watch([
    'searchValue',
    'selectedIds',
  ]);

  const [selectedFields, otherFields] = useMemo(() => {
    const selected = fields.filter(field => selectedIds.includes(field.id));
    const others = fields.filter(field => !selectedIds.includes(field.id));
    return [selected, others];
  }, [fields, selectedIds]);

  const handleCheckboxChange = (checked: boolean, itemId: number) => {
    const newSelectedIds = checked
      ? [...selectedIds, itemId]
      : selectedIds.filter((id: number) => id !== itemId);

    setValue('selectedIds', newSelectedIds, { shouldValidate: true });
  };

  useEffect(() => {
    onSearch(searchValue);
  }, [searchValue, onSearch]);

  useEffect(() => {
    if (data.selectedIds && !isInitialized.current) {
      setValue('selectedIds', data.selectedIds);
      isInitialized.current = true;
    }
  }, [data.selectedIds, setValue]);

  useEffect(() => {
    if (data.items) {
      setValue('items', data.items.map(item => ({
        ...item,
        checked: selectedIds.includes(item.id)
      })));
    }
  }, [data.items, selectedIds, setValue]);

  const getItemKey = useCallback((index: number) => {
    const item = otherFields[index];
    return item?.id.toString() ?? String(index);
  }, [otherFields]);

  const itemData = useMemo(() => ({
    items: otherFields,
    control,
    fields,
    handleCheckboxChange,
  }), [otherFields, control, fields, handleCheckboxChange]);

  return (
    <Grid
      component="form"
      container
      direction="column"
      spacing={1}
      sx={{ justifyContent: 'space-between' }}
      onSubmit={handleSubmit(onSubmit)}
      {...rest}
    >
      <Grid xs={7} sx={{ width: "100%" }}>
        <Box sx={{ padding: theme => theme.spacing(2, 0) }}>
          <Stack component={Item} spacing={1}>
            <Stack direction="row" spacing={2} sx={{ justifyContent: 'space-between' }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                {fieldName}
              </Typography>
            </Stack>

            <TextField
              id="search-field"
              label={`Enter ${fieldName}`}
              variant="outlined"
              autoComplete="off"
              {...register('searchValue')}
              sx={{ backgroundColor: 'common.white' }}
            />

            {fields.length > 0 && (
              <Box sx={{ height: LIST_HEIGHT }}>
                {selectedFields.length > 0 && (
                  <>
                    <List 
                      dense 
                      sx={{ 
                        maxHeight: selectedFields.length > 5 ? LIST_HEIGHT / 2 : 'none',
                        overflow: selectedFields.length > 5 ? 'auto' : 'visible'
                      }}
                    >
                      {selectedFields.map(item => (
                        <Row
                          key={item.internalId}
                          item={item}
                          control={control}
                          fields={fields}
                          handleCheckboxChange={handleCheckboxChange}
                        />
                      ))}
                    </List>
                    <Divider sx={{ my: 2 }} />
                  </>
                )}

                {otherFields.length > 0 && (
                  <>
                    <FixedSizeList
                      height={selectedFields.length > 0 
                        ? LIST_HEIGHT - (Math.min(selectedFields.length, 5) * ROW_HEIGHT) - 40 
                        : LIST_HEIGHT}
                      width="100%"
                      itemCount={otherFields.length}
                      itemSize={ROW_HEIGHT}
                      itemKey={getItemKey}
                      itemData={itemData}
                      overscanCount={5}
                      onItemsRendered={({ visibleStopIndex }) => {
                        if (onLoadMore && visibleStopIndex === otherFields.length - 1 && data.hasMore && visibleStopIndex > lastLoadedIndex.current) {
                          lastLoadedIndex.current = visibleStopIndex;
                          onLoadMore();
                        }
                      }}
                    >
                      {VirtualizedRow}
                    </FixedSizeList>

                    {data.hasMore && data.isValidating && (
                      <Box sx={{ textAlign: 'center', py: 2 }}>
                        <Typography variant="body2" color="text.secondary">
                          Loading more items...
                        </Typography>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            )}
            
            {fields.length === 0 && (
              <ListItem>
                <ListItemText
                  primary={data.isLoading ? "Loading..." : "No results found"}
                  sx={{
                    textAlign: 'center',
                    color: 'text.secondary',
                    py: 2
                  }}
                />
              </ListItem>
            )}
          </Stack>
        </Box>
      </Grid>

      <Grid xs={2} sx={{ width: '100%' }}>
        <Box sx={{ padding: theme => theme.spacing(2, 0) }}>
          <Stack direction="column" spacing={2}>
            <Divider flexItem sx={{ margin: 0 }} />

            <Item sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                size="small"
                type="submit"
                disabled={!formState.isValid || formState.isSubmitting}
              >
                Apply
              </Button>
            </Item>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ListFilterForm;
