import { Stack } from '@mui/material';
import type { TextRenderBoxProps } from './types';
import { InfoTextBoxStyled, InfoTextTypographyStyled } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';

export const InfoText = ({ text, ...props }: TextRenderBoxProps) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1} mb={2} {...props}>
      <InfoTextBoxStyled>
        <FontAwesomeIcon icon={faCircleInfo} />
      </InfoTextBoxStyled>
      <InfoTextTypographyStyled>{text}</InfoTextTypographyStyled>
    </Stack>
  );
};
