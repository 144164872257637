/* eslint-disable @typescript-eslint/no-explicit-any */
import { flatMap, intersection, keys } from 'lodash';
import { EnqueueSnackbar, useSnackbar } from 'notistack';
import { useMemo } from 'react';

import { useUser } from '@components/hooks';
import { additional_error_keys } from './constants';
import {
  BareFetcher,
  Key,
  Middleware,
  SWRConfiguration,
  SWRHook,
  SWRResponse,
} from 'swr';
import { isAdServerError } from './utils';

const getMessage = <Data extends Record<string, any>>(data: Data) =>
  Object.values(data);

export const errorCatcher = (
  e: unknown,
  enqueueSnackbar: EnqueueSnackbar,
  isInternalUser?: boolean
) => {
  const { response } = e as { response: { data: Record<string, any> } };

  if (!response) {
    throw e;
  }

  if (
    !intersection(
      [
        'error',
        'detail',
        'errorType',
        'errorDetail',
        'non_field_errors',
        ...additional_error_keys,
      ],
      keys(response.data)
    ).length
  ) {
    throw e;
  }

  if (isAdServerError(response.data)) {
    let message = response.data.errorDetail;
    if ('errorId' in response.data && isInternalUser) {
      message += ` (${response.data.errorId})`;
    }
    enqueueSnackbar(message, { variant: 'error' });
  } else {
    const messages = flatMap([...getMessage(response.data)]);

    messages.forEach(message =>
      enqueueSnackbar(message, { variant: 'error' })
    );
  }

  throw e;
};

export const useNotification: Middleware =
  (useSWRNext: SWRHook) =>
  <Data = any, Error = any>(
    key: Key,
    fetcher: BareFetcher<Data> | null,
    config: SWRConfiguration<Data, Error>
  ): SWRResponse<Data, Error> => {
    const { user } = useUser();
    const { enqueueSnackbar } = useSnackbar();
    const isInternalUser = useMemo(
      () => !!user && user.is_tvsci_employee,
      [user]
    );
    const modifiedFetcher: BareFetcher<Data> = async (...args) => {
      return (fetcher?.(...args) as Promise<Data>)?.catch?.(e =>
        errorCatcher(e, enqueueSnackbar, isInternalUser)
      );
    };

    return useSWRNext(key, modifiedFetcher, config);
  };
