import React from 'react';
import {
  DataGridPro as MuiDataGridPro,
  GridSlots,
} from '@mui/x-data-grid-pro';
import { DataGridProps } from './types';
import { LinearProgress } from '@mui/material';

export const DataGrid = (props: DataGridProps) => {
  const { columns, hasHeader = false } = props;

  if (!columns) {
    return null;
  }

  return (
    <MuiDataGridPro
      {...props}
      rows={props.rows ?? []}
      sx={{
        '.MuiTablePagination-select.MuiSelect-standard.MuiInputBase-input': {
          paddingRight: 6,
        },
        '.MuiDataGrid-columnHeader--withRightBorder': {
          boxShadow: '4px 0px 8px -4px rgba(0, 0, 0, 0.25)',
        },
        '.MuiDataGrid-cell--withRightBorder': {
          boxShadow: '4px 0px 8px -4px rgba(0, 0, 0, 0.25)',
        },
        '.MuiDataGrid-columnHeader--withLeftBorder': {
          boxShadow: '-4px 0px 8px -4px rgba(0, 0, 0, 0.25)',
        },
        '.MuiDataGrid-cell--withLeftBorder': {
          boxShadow: '-4px 0px 8px -4px rgba(0, 0, 0, 0.25)',
        },
        '.MuiDataGrid-virtualScrollerContent .MuiDataGrid-row:nth-child(even)':
          {
            backgroundColor: 'rgba(250, 250, 250, 1)',
          },
        ...(hasHeader && {
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
        }),
      }}
      slots={
        props.slots ?? {
          loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
        }
      }
    />
  );
};
