import React from 'react';
import {
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Controller, type Control, type FieldArrayWithId } from 'react-hook-form';
import { IFormValues } from './types/ListFilterFormProps.types';

export interface RowProps {
  item: FieldArrayWithId<IFormValues, 'items', 'internalId'>;
  control: Control<IFormValues>;
  fields: FieldArrayWithId<IFormValues, 'items', 'internalId'>[];
  handleCheckboxChange: (checked: boolean, itemId: number) => void;
  style?: React.CSSProperties;
}

export const Row = React.memo(function Row({ item, control, fields, handleCheckboxChange, style }: RowProps) {
  const fieldIndex = fields.findIndex(field => Number(field.id) === item.id);

  return (
    <ListItem style={style} disablePadding>
      <Controller
        name={`items.${fieldIndex}.checked`}
        control={control}
        render={({ field: checkboxField }) => {
          const handleChange = (checked: boolean) => {
            checkboxField.onChange(checked);
            handleCheckboxChange(checked, item.id);
          };

          return (
            <ListItemButton
              role={undefined}
              dense
              onClick={() => handleChange(!checkboxField.value)}
            >
              <ListItemIcon sx={{ minWidth: theme => theme.spacing(3) }}>
                <Checkbox
                  edge="start"
                  checked={checkboxField.value}
                  onChange={(e) => handleChange(e.target.checked)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': `checkbox-list-label-${item.id}` }}
                  sx={{ p: 0 }}
                />
              </ListItemIcon>
              <ListItemText id={`checkbox-list-label-${item.id}`} primary={item.name} />
            </ListItemButton>
          );
        }}
      />
    </ListItem>
  );
});
