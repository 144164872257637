import React from 'react';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Stack,
  Switch,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { AdvancedBox } from '../../../AdvancedBox/AdvancedBox';
import { fields, rotationTexts } from '../constants';
import { useManage } from './hooks/useManage';
import { ManageListElement } from './ManageListElement';
import { CancelButtonStyled } from '../../../styles';

const InfoIconStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.grey[4],
  fontSize: theme.spacing(1.5),
}));

export const Manage = ({
  onClose,
  onSubmit,
  onUpdate,
  isDisplay,
  ...props
}) => {
  const {
    control,
    controlledFields,
    disabled,
    remove,
    update,
    submit,
    weightingRotation,
  } = useManage({
    onSubmit,
    onUpdate,
  });

  return (
    <AdvancedBox
      {...props}
      onClose={onClose}
      header={
        <Stack direction="row" alignItems="center">
          <Stack flex={1}>
            <Typography
              variant="h3"
              data-testid="manage-creative-screen-header"
            >
              Step 2: Manage Creative
            </Typography>
          </Stack>
          {!isDisplay && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="body2">Even Rotation</Typography>
                <Tooltip title={rotationTexts.random}>
                  <InfoIconStyled icon={faInfoCircle} />
                </Tooltip>
              </Stack>
              <Controller
                control={control}
                name={fields.weightingRotation.path}
                render={({ field }) => (
                  <Switch
                    {...field}
                    checked={field.value}
                    onChange={e => {
                      field.onChange(e.target.checked);
                    }}
                  />
                )}
              />
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="body2">Weighted Rotation</Typography>
                <Tooltip title={rotationTexts.weighted}>
                  <InfoIconStyled icon={faInfoCircle} />
                </Tooltip>
              </Stack>
            </Stack>
          )}
        </Stack>
      }
    >
      <Stack flex={1} gap={7.5}>
        <Stack flex={1}>
          {controlledFields.map((field, idx) => (
            <ManageListElement
              onRemove={remove(idx)}
              onUpdate={update(idx)}
              field={field}
              weightingRotation={weightingRotation}
              key={field.id}
              index={idx}
              isDisplay={isDisplay}
            />
          ))}
        </Stack>
        <Stack>
          <Stack direction="row" justifyContent="space-between">
            <Stack>
              <CancelButtonStyled variant="text" onClick={onClose}>
                Cancel
              </CancelButtonStyled>
            </Stack>
            <Button
              data-testid="save-creative-button"
              disabled={disabled}
              onClick={submit}
              variant="contained"
            >
              Save Creative
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </AdvancedBox>
  );
};

Manage.propTypes = {
  ...AdvancedBox.propTypes,
  onUpdate: PropTypes.func,
  isDisplay: PropTypes.bool,
};
