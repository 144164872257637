import { Stack, Typography } from '@mui/material';
import { map, slice } from 'lodash';
import React from 'react';
import { PenStyled } from './styles';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { DataListElementProps } from './types';

export const DataListElement = ({
  data,
  onAdvanced,
}: DataListElementProps) => (
  <Stack direction="row" alignItems="center" spacing={1}>
    <Typography variant="body2">
      {map(slice(data, 0, 2), 'audience_name').join(', ')}{' '}
      {data.length > 2 && `+${data.length - 2} more...`}
    </Typography>
    <PenStyled role="button" onClick={onAdvanced} icon={faPen} />
  </Stack>
);
