import React, { useContext, useMemo } from 'react';
import {
  Button,
  Chip,
  Menu,
  MenuItem,
  TableRow,
  TableCell,
  Typography,
  IconButton,
} from '@mui/material';
import { classes } from './styles';
import { formatToUSD } from '@components/util';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import clsx from 'clsx';
import { useBWSync } from '@components/hooks/bwSync';
import DeliverySignal from '../../DeliverySignal';
import { Statuses } from './statuses';
import { useDomain } from '@components/hooks/domain';
import AdvertiserContext from '@components/AdvertiserContext';
import AspectRatioOutlinedIcon from '@mui/icons-material/AspectRatioOutlined';
import { formatEndDate, renderPauseActivateStatus } from './utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Campaign } from '@local-types/campaign';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartSimple } from '@fortawesome/pro-solid-svg-icons';
import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';
import { AdGroup } from '@local-types/group';

const staticStatusIconRender = (display: AdGroup) => {
  if (display.pending_active) {
    return <Chip label="Pending" className={classes.pending} />;
  }

  if (display.draft) {
    return <Chip label="Draft" className={classes.draft} />;
  }

  if (display.active) {
    return <Chip label="Active" className={classes.active} />;
  }

  return <Chip label="Paused" className={classes.paused} />;
};

export const AdGroupsTableStaticDisplayRow = ({
  display,
  index,
  campaignId,
  campaign,
  handleManageDisplay,
  handleOpenMenu,
  handleCloseMenu,
  handleOpenRealTimeModal,
  handlePauseActive,
  currentAnchorEl,
  targetMenu,
}: {
  display: AdGroup;
  index: number;
  campaignId: number;
  campaign: Campaign;
  handleManageDisplay: (display: AdGroup) => void;
  handleOpenRealTimeModal: (
    event: React.MouseEvent<HTMLElement>,
    adGroup: AdGroup
  ) => void;
  handleOpenMenu: (
    event: React.MouseEvent<HTMLElement>,
    rowId: number
  ) => void;
  handleCloseMenu: () => void;
  handlePauseActive: (adGroup: AdGroup, isDisplay: boolean) => void;
  currentAnchorEl: HTMLElement | null;
  targetMenu: number | null;
}) => {
  const { showBWSync, renderBWSyncStatus } = useBWSync();
  const domain = useDomain();
  const adContext = useContext(AdvertiserContext);
  const { realtimeDeliveryModal } = useLDFlags();

  const isBidEnabled = useMemo(
    () => adContext.tvsciq && adContext.tvsciq !== 'DISABLED',
    [adContext.tvsciq]
  );

  const labelId = `display-table-checkbox-${index}`;
  const { stats_cache: stats } = display;

  return (
    <TableRow hover role="checkbox" tabIndex={-1} data-testid="adgroup-row">
      <TableCell className={classes.tableCell} align="left">
        {display.temporary ? '-' : display.id}
      </TableCell>

      <TableCell
        className={classes.tableCell}
        component="th"
        id={labelId}
        scope="row"
      >
        <Typography className={classes.display} variant="h5">
          <Link
            className={classes.link}
            to={`/campaigns/${campaignId}/adgroups/${display.id}/displays`}
          >
            {display.name}
          </Link>
        </Typography>
      </TableCell>
      {realtimeDeliveryModal && (
        <TableCell align="left">
          <IconButton
            color="secondary"
            aria-label="open the realtime delivery graph modal"
            onClick={event => handleOpenRealTimeModal(event, display)}
          >
            <FontAwesomeIcon icon={faChartSimple} fontSize="small" />
          </IconButton>
        </TableCell>
      )}

      <TableCell className={classes.tableCell} align="left">
        {campaign.name}
      </TableCell>

      <TableCell className={classes.tableCell} align="left">
        {moment(display.start_date).format('l')}
      </TableCell>

      <TableCell className={classes.tableCell} align="left">
        {formatEndDate(display.end_date)}
      </TableCell>

      <TableCell className={classes.tableCell} align="left">
        {display.cpm}
      </TableCell>

      <TableCell className={clsx(classes.stats)} align="left">
        {stats != null ? formatToUSD(stats.total_spend) : '-'}
      </TableCell>

      {domain.default && (
        <TableCell className={clsx(classes.stats)} align="left">
          {stats != null ? formatToUSD(stats.budget_remaining) : '-'}
        </TableCell>
      )}

      <TableCell className={clsx(classes.stats)} align="left">
        {stats != null ? stats.visit_count : '-'}
      </TableCell>

      <TableCell
        className={clsx(classes.tableCell, classes.stats)}
        align="left"
      >
        {stats != null ? formatToUSD(stats.cost_per_visit) : '-'}
      </TableCell>

      {isBidEnabled && <TableCell>-</TableCell>}

      {showBWSync && (
        <TableCell align="left">
          {renderBWSyncStatus(
            display,
            (display.status as any) !== Statuses.ACTIVE // eslint-disable-line @typescript-eslint/no-explicit-any
          )}
        </TableCell>
      )}

      <TableCell align="left">
        <DeliverySignal
          signal={display.active ? display.delivery_signal : null}
        />
      </TableCell>

      <TableCell className={classes.height} align="left">
        {staticStatusIconRender(display)}
      </TableCell>

      <TableCell className={classes.height} align="left">
        <Button
          className={classes.actionButton}
          onClick={event => handleOpenMenu(event, display.id)}
          endIcon={<ExpandMoreIcon />}
        >
          Manage
        </Button>

        <Menu
          anchorEl={currentAnchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 40, horizontal: 'right' }}
          open={targetMenu === display.id}
          onClick={handleCloseMenu}
        >
          <MenuItem onClick={() => handleManageDisplay(display)}>
            <AspectRatioOutlinedIcon
              fontSize="small"
              className={classes.menuIcon}
            />
            Manage Ad Group
          </MenuItem>
          {!display.draft && (
            <MenuItem onClick={() => handlePauseActive(display, true)}>
              {renderPauseActivateStatus(
                display.active ? Statuses.ACTIVE : Statuses.PAUSED
              )}
            </MenuItem>
          )}
        </Menu>
      </TableCell>
    </TableRow>
  );
};
