import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, IconButton, Stack, styled } from '@mui/material';

export const OverlayBoxStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(6),
  position: 'fixed',
  left: `calc(${theme.spacing(15)} + 22.18%)`,
  right: 0,
  top: theme.spacing(8), // 64px offset for site header
  bottom: 0,
  zIndex: 999,
  '@media (max-width: 1699px)': {
    left: theme.spacing(13.625), // 109px offset for left nav
  },
}));

export const WrapperBoxStyled = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: 20,
  boxShadow: theme.shadows[3],
  flex: 1,
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
  zIndex: 999,
}));

export const AdvancedBoxStyled = styled(Stack)(({ theme }) => ({
  margin: theme.spacing(5),
  flex: 1,
  overflow: 'auto',
}));

export const ContentWrapperStyled = styled(Stack)(() => ({
  marginTop: 0,
  flex: 'auto',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
}));

export const BackIconStyled = styled(IconButton)(({ theme }) => ({
  color: theme.palette.blue[6],
}));

export const DividerStyled = styled(Divider)(({ theme }) => ({
  marginRight: theme.spacing(1.75),
  marginLeft: theme.spacing(1),
  borderWidth: 1,
}));

export const HeaderWrapperStyled = styled(Stack)(() => ({
  width: '100%',
}));

export const InfoBoxInfoIconStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  width: theme.spacing(2.25),
  height: theme.spacing(2.25),
}));

export const InfoBoxCloseStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.grey[6],
}));

export const InfoBoxStyled = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  padding: theme.spacing(1, 2, 1, 5),
  color: theme.palette.grey[6],
}));

export const BoxStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  zIndex: 9,
}));
