import React from 'react';
import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ButtonCard } from '@v2/components/campaign/ButtonCard';
import { Icon } from '@v2/components/campaign/Icon';
import PropTypes from 'prop-types';

import { useCopy } from '@hooks/copy';
import { Copies, CampaignTypes } from './constants';
import {
  ContentStyled,
  QuickstartCardInnerStyled,
  QuickstartCardStyled,
} from './styles';

export const CampaignTypeForm = ({ onSubmit, ...props }) => {
  const Copy = useCopy(Copies);
  const theme = useTheme();

  return (
    <Box sx={{ background: 'common.white' }} {...props}>
      <Stack direction="row" justifyContent="center" spacing={4}>
        <ButtonCard
          id="Linear TV"
          testId="campaign-type-from-scratch"
          cardHeaderEnabled={false}
          customIcon={Copy.fromScratchCustomIcon}
          iconName={Copy.fromScratchIcon}
          iconColor="#1DAFFF"
          iconMargin="55px 0 35px"
          onSelect={() => onSubmit(CampaignTypes.FROM_SCRATCH)}
        >
          <ContentStyled>
            <Typography>I want to build a</Typography>
            <Typography variant="h4" mb={1}>
              {Copy.fromScratchHeader}
            </Typography>
            {Copy.fromScratchBody}
          </ContentStyled>
        </ButtonCard>
        <QuickstartCardStyled
          testId="campaign-type-quick-start"
          disabled
          id="Streaming TV"
          cardHeaderEnabled={false}
          cardHeaderTitle="RECOMMENDED"
          customIcon={Copy.quickStartCustomIcon}
          iconColor="green.8"
          iconMargin="55px 0 35px"
          cardFooterBGColor={theme.palette.tip['light']}
          onSelect={() => onSubmit(CampaignTypes.QUICK_START)}
        >
          <Box
            sx={{
              position: 'absolute',
              top: -24,
              right: -24,
            }}
          >
            <Icon name="comingSoonYellow" />
          </Box>
          <QuickstartCardInnerStyled>
            <Typography>I want to build a</Typography>
            <Typography variant="h4" mb={1}>
              {Copy.quickStartHeader}
            </Typography>
            {Copy.quickStartBody}
          </QuickstartCardInnerStyled>
        </QuickstartCardStyled>
      </Stack>
    </Box>
  );
};

CampaignTypeForm.defaultProps = {
  data: {
    type: '',
  },
};

CampaignTypeForm.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};
