/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
} from '@mui/material';
import React, { useContext, useMemo } from 'react';
import { classes } from './styles';
import {
  renderPauseActivateStatus,
  renderArchiveUnarchiveStatus,
  formatEndDate,
} from './utils';
import DeliverySignal from '@components/DeliverySignal';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import clsx from 'clsx';
import { formatToUSD } from '@components/util';
import { useDomain } from '@components/hooks/domain';
import { useBWSync } from '@components/hooks/bwSync';
import { BidStrategyBadge } from '@components/BidStrategy';
import { Statuses } from './statuses';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PeopleOutlineOutlinedIcon from '@mui/icons-material/PeopleOutlineOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AdvertiserContext from '@components/AdvertiserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';
import { faChartSimple } from '@fortawesome/pro-solid-svg-icons';
import { Campaign } from '@local-types';
import { AdGroup } from '@local-types/group';
import { useFlags } from '@components/hooks';

export const AdGroupsTableLineItemRow = ({
  adGroup,
  campaignId,
  campaign,
  index,
  handleOpenMenu,
  handleCloseMenu,
  currentAnchorEl,
  targetMenu,
  handleManageAdGroup,
  handleDuplicateAdGroup,
  handleOpenWeighting,
  handlePauseActive,
  handleOpenBid,
  handleIsDuplicatable,
  handleOpenRealTimeModal,
  handleArchiveUnarchiveAdGroup,
}: {
  adGroup: AdGroup;
  campaignId: number;
  campaign: Campaign;
  index: number;
  handleOpenMenu: (event: any, rowId: number) => void;
  handleCloseMenu: () => void;
  currentAnchorEl: any;
  targetMenu: number | null;
  handleManageAdGroup: (adGroup: AdGroup) => void;
  handleDuplicateAdGroup: (adGroup: AdGroup) => void;
  handleOpenWeighting: (adGroup: AdGroup) => void;
  handlePauseActive: (adGroup: AdGroup, isDisplay?: boolean) => void;
  handleOpenBid: (adGroup: AdGroup) => void;
  handleIsDuplicatable: (adGroup: AdGroup) => boolean;
  handleOpenRealTimeModal: (event: any, adGroup: AdGroup) => void;
  handleArchiveUnarchiveAdGroup: (adGroup: AdGroup) => void;
}) => {
  const labelId = `ad-groups-table-checkbox-${index}`;
  const { stats_cache: stats } = adGroup;
  const domain = useDomain();
  const { showBWSync, renderBWSyncStatus } = useBWSync();
  const adContext = useContext(AdvertiserContext);
  const { realtimeDeliveryModal } = useLDFlags();
  const isBidEnabled = useMemo(
    () => adContext.tvsciq && adContext.tvsciq !== 'DISABLED',
    [adContext.tvsciq]
  );
  const { flags } = useFlags();
  const canArchive =
    flags.USER_CAN_ARCHIVE &&
    (adGroup.status === Statuses.PAUSED ||
      adGroup.status === Statuses.ARCHIVED);

  const getBidStrategy = (bidStrategyId: string | number) => {
    if (!bidStrategyId) return null;

    const { bidstrategy_set: strategies } = adContext;
    const strategy = strategies.find(
      item => (item as any).id == bidStrategyId
    );

    return strategy;
  };

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={adGroup.id}
      data-testid="adgroup-row"
    >
      <TableCell className={classes.tableCell} align="left">
        {adGroup.temporary ? '-' : adGroup.id}
      </TableCell>
      <TableCell
        className={classes.tableCell}
        component="th"
        id={labelId}
        scope="row"
        align="left"
      >
        <Link
          data-testid={`ad-group-${campaign.name}`}
          className={classes.link}
          to={`/campaigns/${campaignId}/adgroups/${adGroup.id}/creative`}
        >
          {adGroup.name}
        </Link>
      </TableCell>
      {realtimeDeliveryModal && (
        <TableCell align="left">
          <IconButton
            color="secondary"
            aria-label="open the realtime delivery graph modal"
            onClick={event => handleOpenRealTimeModal(event, adGroup)}
          >
            <FontAwesomeIcon icon={faChartSimple} fontSize="small" />
          </IconButton>
        </TableCell>
      )}
      <TableCell className={classes.tableCell} align="left">
        {campaign.name}
      </TableCell>
      <TableCell className={classes.tableCell} align="left">
        {moment(adGroup.start_date).format('l')}
      </TableCell>
      <TableCell className={classes.tableCell} align="left">
        {formatEndDate(adGroup.end_date)}
      </TableCell>

      <TableCell className={classes.tableCell} align="left">
        ${adGroup.cpm}
      </TableCell>

      <TableCell className={clsx(classes.stats)} align="left">
        {stats != null ? formatToUSD(stats.total_spend) : '-'}
      </TableCell>

      {domain.default && (
        <TableCell className={clsx(classes.stats)} align="left">
          {stats != null ? formatToUSD(stats.budget_remaining) : '-'}
        </TableCell>
      )}

      <TableCell className={clsx(classes.stats)} align="left">
        {stats && stats.visit_count != null ? stats.visit_count : '-'}
      </TableCell>

      <TableCell className={clsx(classes.stats)} align="left">
        {stats && stats.cost_per_visit != null
          ? formatToUSD(stats.cost_per_visit)
          : '-'}
      </TableCell>

      {isBidEnabled && (
        <TableCell
          className={clsx(classes.tableCell, classes.stats)}
          align="left"
        >
          <BidStrategyBadge
            bidStrategy={getBidStrategy(adGroup.bid_strategy)}
          />
        </TableCell>
      )}

      {showBWSync && (
        <TableCell align="left">
          {renderBWSyncStatus(adGroup, adGroup.status !== Statuses.ACTIVE)}
        </TableCell>
      )}

      <TableCell className={classes.tableCell} align="left">
        <DeliverySignal
          signal={adGroup.active ? adGroup.delivery_signal : null}
        />
      </TableCell>

      <TableCell className={classes.height} align="left">
        {adGroup.status === Statuses.ACTIVE && (
          <Chip label="Active" className={classes.active} />
        )}
        {adGroup.status === Statuses.PENDING && (
          <Chip label="Pending" className={classes.pending} />
        )}
        {adGroup.status === Statuses.PAUSED && (
          <Chip label="Paused" className={classes.paused} />
        )}
        {adGroup.status === Statuses.DRAFT && (
          <Chip label="Draft" className={classes.draft} />
        )}
        {adGroup.status === Statuses.ARCHIVED && (
          <Chip label="Archived" className={classes.archived} />
        )}
      </TableCell>

      <TableCell className={classes.height} align="left">
        <Button
          className={classes.actionButton}
          onClick={event => handleOpenMenu(event, adGroup.id)}
          endIcon={<ExpandMoreIcon />}
        >
          Manage
        </Button>

        <Menu
          anchorEl={currentAnchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={targetMenu === adGroup.id}
          onClick={handleCloseMenu}
        >
          {adGroup.status !== Statuses.ARCHIVED && (
            <MenuItem onClick={() => handleManageAdGroup(adGroup)}>
              <PeopleOutlineOutlinedIcon
                fontSize="small"
                className={classes.menuIcon}
              />
              Manage Ad Group
            </MenuItem>
          )}

          {adGroup.status !== Statuses.DRAFT && (
            <MenuItem
              disabled={handleIsDuplicatable(adGroup)}
              onClick={() => handleDuplicateAdGroup(adGroup)}
            >
              <FileCopyOutlinedIcon
                fontSize="small"
                className={classes.menuIcon}
              />
              Duplicate Ad Group
            </MenuItem>
          )}

          {adGroup.status !== Statuses.ARCHIVED && (
            <MenuItem onClick={() => handleOpenWeighting(adGroup)}>
              <OndemandVideoIcon
                fontSize="small"
                className={classes.menuIcon}
              />
              Edit Weighting
            </MenuItem>
          )}

          {!adGroup.is_control_group &&
            adGroup.status !== Statuses.DRAFT &&
            adGroup.status !== Statuses.ARCHIVED && (
              <MenuItem onClick={() => handleOpenBid(adGroup)}>
                <EditOutlinedIcon
                  fontSize="small"
                  className={classes.menuIcon}
                />
                Edit Bid
              </MenuItem>
            )}

          {!adGroup.is_control_group &&
            (adGroup.status === Statuses.ACTIVE ||
              adGroup.status === Statuses.PAUSED) && (
              <MenuItem onClick={() => handlePauseActive(adGroup)}>
                {renderPauseActivateStatus(adGroup.status)}
              </MenuItem>
            )}

          {canArchive && (
            <MenuItem onClick={() => handleArchiveUnarchiveAdGroup(adGroup)}>
              {renderArchiveUnarchiveStatus(
                adGroup.status as number,
                'Ad Group'
              )}
            </MenuItem>
          )}
        </Menu>
      </TableCell>
    </TableRow>
  );
};
