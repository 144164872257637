import { useDomain } from '@components/hooks/domain';
import { AccordionOption } from '../types';
import React, { useState } from 'react';

export const useOptionsAccordion = ({
  options,
  onChange,
}: {
  value: string;
  options: AccordionOption[];
  onChange: (
    event: React.SyntheticEvent<Element>,
    option: AccordionOption
  ) => void;
}) => {
  const domain = useDomain();

  const [lockedOptionsExpandedStates, setLockedOptionsExpandedStates] =
    useState<Record<string, boolean>>({});

  const isPeacockDomain = domain.peacock;

  const handleChange = (_: React.SyntheticEvent<Element>, panel: string) => {
    const option = options.find(obj => obj.value == panel);

    if (!option) return;

    if (option.locked) {
      setLockedOptionsExpandedStates({
        ...lockedOptionsExpandedStates,
        [panel]: !lockedOptionsExpandedStates[panel],
      });

      return;
    }

    onChange?.(_, option);
  };

  return {
    change: handleChange,
    lockedOptionsExpandedStates,
    isPeacockDomain,
  };
};
