import { ThemeProvider } from '@mui/material';
import { defaultTheme_v2 } from '@themes/default_v2';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useDeferredValue,
  useMemo,
  useState,
} from 'react';
import { useGetCampaigns } from '@apis/campaigns';
import { GridSortModel, GridSortItem } from '@mui/x-data-grid-pro';
import {
  Status,
  Campaign,
} from '@local-types';
import { uniqBy } from 'lodash';

export interface CampaignsTableContextType {
  rowCount: number;
  campaigns: Campaign[];
  isLoading: boolean;
  isValidating: boolean;
  handleManageBudget: (campaign: Campaign) => void;
  handleDeleteDraft: (campaign: Campaign) => void;
  handleDuplicateCampaign: (campaign: Campaign) => void;
  handleDraftCampaign: (id: number) => void;
  handleManageCampaign: (campaign: Campaign) => void;
  handleArchiveUnarchiveCampaign: (campaign: Campaign) => void;
  handlePauseActive: (campaign: Campaign) => void;
  handleDeleteCampaignComplete: (campaign: Campaign) => void;
  handleSaveCampaign: (campaign: Campaign) => void;
  refreshCampaigns: () => Promise<void>;
  sortModel: GridSortModel;
  setSortModel: (sortModel: GridSortModel) => void;
  onFilterChange: (filter: string) => void;
  filter: string;
  hasMore: boolean;
  setSize: Dispatch<SetStateAction<number>>;
}

export const CampaignsTableContext = createContext<CampaignsTableContextType>(
  {
    rowCount: 0,
    campaigns: [],
    isLoading: false,
    isValidating: false,
    handleManageBudget: () => {},
    handleDeleteDraft: () => {},
    handleDuplicateCampaign: () => {},
    handleDraftCampaign: () => {},
    handleManageCampaign: () => {},
    handleArchiveUnarchiveCampaign: () => {},
    handlePauseActive: () => {},
    handleDeleteCampaignComplete: () => {},
    handleSaveCampaign: () => {},
    refreshCampaigns: async () => {},
    sortModel: [
      {
        field: 'name',
        sort: 'asc',
      },
    ],
    setSortModel: () => {},
    onFilterChange: () => {},
    filter: '',
    setSize: () => {},
    hasMore: false,
  },
);

export interface CampaignsTableContextProviderProps
  extends Partial<CampaignsTableContextType> {
  children: React.ReactNode;
  params: Record<string, boolean>;
  setIsDupeCampaign: Dispatch<SetStateAction<boolean>>;
}

const getOrderingString = (sortModel: GridSortModel) => {
  if (!sortModel.length) {
    return 'status,-start_date';
  }
  if (sortModel[0].field === 'status') {
    return sortModel[0].sort === 'desc'
      ? '-status,-start_date'
      : 'status,-start_date';
  }
  return sortModel
    .map((item: GridSortItem) => {
      return `${item.sort === 'desc' ? '-' : ''}${item.field}`;
    })
    .join(',');
};

export const CampaignsTableContextProvider = ({
  children,
  params,
  setIsDupeCampaign,
  ...props
}: CampaignsTableContextProviderProps) => {
  const [filter, setFilter] = useState<string>('');
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const deferredFilter = useDeferredValue(filter);

  const {
    items,
    hasMore,
    isLoading,
    isValidating,
    setSize,
    totalItems,
    patchCampaign,
    update: updateCampaignCache,
    remove: removeFromCampaignCache,
    mutate: refreshCampaigns,
  } = useGetCampaigns({
    search: deferredFilter || undefined,
    ordering: getOrderingString(sortModel),
    ...params,
  });

  const { totalCount, campaigns } = useMemo(() => {
    const isFiltered = filter !== '';

    const totalCount = isFiltered ? items.length : totalItems;
    return { totalCount, campaigns: uniqBy(items, 'id') };
  }, [items, sortModel, filter]);

  const handlePauseActive = async (campaign: Campaign) => {
    // @ts-expect-error does not match up with trigger
    const response = await patchCampaign({
      id: campaign.id,
      body: {
        status:
          campaign.status === Status.ACTIVE ? Status.INACTIVE : Status.ACTIVE,
      },
    });
    return updateCampaignCache(response?.data);
  };

  const handleSaveCampaign = () => {
    setIsDupeCampaign(false);
    refreshCampaigns();
  };

  const handleDeleteCampaignComplete = (campaign: Campaign) => {
    removeFromCampaignCache(campaign.id);
  };

  return (
    <ThemeProvider theme={defaultTheme_v2}>
      <CampaignsTableContext.Provider
        value={
          {
            ...props,
            setSortModel,
            sortModel,
            rowCount: totalCount,
            campaigns: campaigns as unknown as Campaign[],
            isLoading,
            isValidating,
            onFilterChange: setFilter,
            filter,
            setSize,
            hasMore,
            handlePauseActive,
            handleSaveCampaign,
            handleDeleteCampaignComplete,
            refreshCampaigns,
          } as CampaignsTableContextType
        }
      >
        {children}
      </CampaignsTableContext.Provider>
    </ThemeProvider>
  );
};

export const useCampaignsTable = () => {
  return useContext(CampaignsTableContext);
};
