import React from 'react';

import { Divider, Stack } from '@mui/material';
import { AdvancedBoxFooterProps } from './types';
import { BoxStyled } from './styles';

export const AdvancedBoxFooter = ({ children }: AdvancedBoxFooterProps) => {
  return (
    <BoxStyled>
      <Divider />
      <Stack
        direction="row"
        justifyContent="space-between"
        mt={4}
        pr={2}
        pb={2}
      >
        {children}
      </Stack>
    </BoxStyled>
  );
};
