import React, { useState } from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { Box, Button, Menu, MenuItem, MenuItemProps } from '@mui/material';
import { MoreVert } from '@mui/icons-material';

export type ActionMenuItem = {
  id: string;
  label: React.ReactNode;
  icon: React.ReactNode;
  onClick: () => void;
} & MenuItemProps;

export const DataGridActionMenuRenderer = ({
  params,
  items,
}: {
  params: Partial<GridCellParams>;
  items: ActionMenuItem[];
}) => {
  const { id } = params.row ?? {};
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <Button
        sx={{ color: 'primary.main' }}
        variant="text"
        size="small"
        id={`action-menu-${id}`}
        aria-controls={open ? 'action-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVert />
      </Button>
      <Menu
        id={`action-menu-${id}`}
        data-testid="action-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': `action-menu-${id}`,
        }}
      >
        {items.map(item => (
          <MenuItem
            {...item}
            key={item.id}
            data-testid={`action-menu-item-${item.id}`}
            onClick={() => {
              item.onClick();
              handleClose();
            }}
            sx={{ display: 'flex', gap: 1.5 }}
          >
            {item.icon}
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
