import React, { useMemo } from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import {
  CreateOutlined,
  FileCopyOutlined,
  AttachMoney,
  Delete,
} from '@mui/icons-material';
import { useFlags } from '@components/hooks';
import { useCampaignsTable } from '../CampaignsTableContext';
import { Status } from '@local-types';
import { CampaignArchiveRenderer } from './CampaignArchiveRenderer';
import { CampaignPauseActivateRenderer } from './CampaignPauseActivateRenderer';
import {
  ActionMenuItem,
  DataGridActionMenuRenderer,
} from '@v2/components/ui/DataGrid/renderers/DataGridActionMenuRenderer';

export const CampaignActionMenuCellRenderer = (
  params: Partial<GridCellParams>,
) => {
  const { flags } = useFlags();

  const { id, experiment_type } = params.row ?? {};
  const { status } = params.row ?? {};

  const {
    handleManageBudget,
    handleManageCampaign,
    handleDuplicateCampaign,
    handleArchiveUnarchiveCampaign,
    handlePauseActive,
    handleDraftCampaign,
    handleDeleteDraft,
  } = useCampaignsTable();

  if (!id) {
    return null;
  }

  const isDraft = status === Status.DRAFT;
  const isArchived = status === Status.ARCHIVED;
  const canManageCampaign = !experiment_type && !isDraft;
  const canEditBudget = !experiment_type && !isDraft && !isArchived;
  const canPauseActive =
    !isDraft && (status === Status.INACTIVE || status === Status.ACTIVE);
  const canArchive =
    flags.USER_CAN_ARCHIVE &&
    (status === Status.INACTIVE || status === Status.ARCHIVED);

  const items = useMemo(() => {
    return [
      isDraft && {
        id: 'edit',
        label: 'Edit',
        icon: <CreateOutlined fontSize="small" />,
        onClick: () => {
          handleDraftCampaign(id);
        },
      },
      isDraft && {
        id: 'delete',
        label: 'Delete',
        icon: <Delete fontSize="small" />,
        onClick: () => {
          handleDeleteDraft(params.row);
        },
      },
      canManageCampaign && {
        id: 'manage',
        label: 'Manage Campaign',
        icon: <CreateOutlined fontSize="small" />,
        onClick: () => {
          handleManageCampaign(params.row);
        },
      },
      !isDraft && {
        id: 'duplicate',
        label: 'Duplicate Campaign',
        icon: <FileCopyOutlined fontSize="small" />,
        onClick: () => {
          handleDuplicateCampaign(params.row);
        },
      },
      canEditBudget && {
        id: 'budget',
        onClick: () => {
          handleManageBudget(params.row);
        },
        icon: <AttachMoney fontSize="small" />,
        label: 'Edit Budget',
      },
      canPauseActive && {
        id: 'pause',
        onClick: () => {
          handlePauseActive(params.row);
        },
        label: <CampaignPauseActivateRenderer status={status} />,
      },
      canArchive && {
        id: 'archive',
        onClick: () => {
          handleArchiveUnarchiveCampaign(params.row);
        },
        label: <CampaignArchiveRenderer status={status} />,
      },
    ].filter(Boolean);
  }, [
    isDraft,
    canManageCampaign,
    canArchive,
    canPauseActive,
    canEditBudget,
    status,
    params.row,
  ]) as ActionMenuItem[];

  return <DataGridActionMenuRenderer params={params} items={items} />;
};
