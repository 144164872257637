import React, { Fragment, useContext, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  Grid,
  Toolbar,
  Button,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { Helmet } from 'react-helmet-async';

import { usePermissions, useUser, useFlags } from './hooks';
import { TvsciLogo } from './logos';
import { MainListItems, SecondaryListItems } from './NavListItems';
import AdvertiserContext from './AdvertiserContext';
import LoginButton from './LoginButton';
import '../styles/components/example-img.css';
import { Scopes } from '../constants';

import peacockFavicon from '../images/nbcu/favicon_32x32.png';
import { useDomain } from './hooks/domain';
import { useRoles } from './hooks/roles';

const PREFIX = 'AppHeader';

const classes = {
  toolbar: `${PREFIX}-toolbar`,
  toolbarIcon: `${PREFIX}-toolbarIcon`,
  appBar: `${PREFIX}-appBar`,
  appBarShift: `${PREFIX}-appBarShift`,
  appBarSpacer: `${PREFIX}-appBarSpacer`,
  content: `${PREFIX}-content`,
  innerWrap: `${PREFIX}-innerWrap`,
  loggedIn: `${PREFIX}-loggedIn`,
  logo: `${PREFIX}-logo`,
  logoFooter: `${PREFIX}-logoFooter`,
  logoNBCU: `${PREFIX}-logoNBCU`,
  poweredBy: `${PREFIX}-poweredBy`,
  padding: `${PREFIX}-padding`,
  sideNav: `${PREFIX}-sideNav`,
  skipSaveLink: `${PREFIX}-skipSaveLink`,
  nbcuFooter: `${PREFIX}-nbcuFooter`,
};

const Root = styled(Box, {
  shouldForwardProp: prop => prop !== 'themeVersion',
})(({ themeVersion, theme }) => ({
  display: 'flex',
  width: '100%',

  [`& .${classes.toolbar}`]: {
    paddingRight: 24, // keep right padding when drawer closed
  },

  [`& .${classes.toolbarIcon}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },

  [`& .${classes.appBar}`]: {
    maxHeight: 64,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  [`& .${classes.appBarShift}`]: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  [`& .${classes.appBarSpacer}`]: theme.mixins.toolbar,

  [`& .${classes.content}`]: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },

  [`& .${classes.innerWrap}`]: {
    width: '100%',
  },

  [`& .${classes.loggedIn}`]: {
    backgroundColor: themeVersion === 'v2' ? `#0E5880` : `#088C60`,
  },

  [`& .${classes.logo}`]: {
    width: 148,
    height: `auto`,
    fill: themeVersion === 'v2' ? `#A1E5CE` : `white`,
  },

  [`& .${classes.logoFooter}`]: {
    width: 95,
    height: `auto`,
    fill: 'white',
  },

  [`& .${classes.logoNBCU}`]: {
    width: theme.spacing(25),
    height: `auto`,
  },

  [`& .${classes.poweredBy}`]: {
    fontSize: '0.625rem',
  },

  [`& .${classes.padding}`]: {
    paddingBottom: theme.spacing(5),
  },

  [`& .${classes.sideNav}`]: {
    width: '100%',
  },

  [`& .${classes.skipSaveLink}`]: {
    textDecoration: 'none',
  },

  [`&.${classes.nbcuFooter}`]: {
    position: 'fixed',
    backgroundColor: '#131719',
    bottom: 0,
    boxSizing: 'border-box',
    color: '#979797',
    height: 40,
    paddingRight: 20,
    width: '100%',
    zIndex: 1201,
  },
}));

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: prop => prop !== 'themeVersion',
})(({ themeVersion, theme, open }) => ({
  [`& .MuiDrawer-paper`]: {
    backgroundColor: themeVersion === 'v2' ? '#0A405D' : '#04593D',
    color: '#fff',
    zIndex: 1000,
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

    a: {
      color: '#fff',
      textDecoration: 'none',
    },

    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },

  [`& .MuiListItemIcon-root, & .MuiListSubheader-root`]: {
    color: '#fff',
  },

  [`& .Drawer-nav_wrap`]: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-between',
  },
}));

const tvsFavicon =
  'https://cdn-5faad614c1ac181bf8a992b7.closte.com/wp-content/uploads/2020/12/cropped-tv-s-icon-32x32.png';

const drawerWidth = `110px`;

/////////////////////////////
// APP HEADER COMPONENT
/////////////////////////////
const AppHeader = props => {
  const {
    isSaveButtonDisabled,
    navSkipSaveStatus,
    triggerSave,
    className,
    children,
    hidenav,
    step,
    ...rest
  } = props;
  const adContext = useContext(AdvertiserContext);
  const { hasPermission } = usePermissions();
  const domain = useDomain();
  const { flags, Flags } = useFlags();
  const { user } = useUser();
  const roles = useRoles();

  const verticalAppEnabled = flags[Flags.USER_GETS_VERTICAL_CAMPAIGN];
  const themeVersion = verticalAppEnabled ? 'v2' : null;

  const faviconPath = domain.peacock ? peacockFavicon : tvsFavicon;

  const showCampaignsLink =
    (hasPermission([Scopes.CAN_LIST_CAMPAIGN]) &&
      adContext?.cost_model !== 'CPA') ||
    user?.is_tvsci_employee ||
    roles.TENANT_ADMIN;
  const showReportsLink =
    hasPermission([Scopes.CAN_READ_REPORTING]) || roles.TENANT_ADMIN;
  const showBusinessManagerLink = hasPermission([
    Scopes.CAN_VIEW_BUSINESS_MANAGER,
  ]);

  const isInternalUser = useMemo(
    () => user?.is_tvsci_employee || adContext?.cost_model === 'CPM',
    [user, adContext]
  );

  const isTenantAdmin = roles.TENANT_ADMIN;

  function NavSkipSave() {
    if (navSkipSaveStatus === 'skip') {
      return (
        <Grid item container xs={6} justifyContent="flex-end">
          <Link to="/" className={classes.skipSaveLink}>
            <Button color="primary" variant="contained" disableElevation>
              SKIP <ArrowForwardIcon fontSize="small" />
            </Button>
          </Link>
        </Grid>
      );
    } else if (navSkipSaveStatus === 'saveAndExit') {
      return (
        <Grid item container xs={6} justifyContent="flex-end">
          <Button
            className="Nav-exit-btn"
            onClick={() => triggerSave(step, true, null)}
            color="primary"
            variant="contained"
            disableElevation
            disabled={isSaveButtonDisabled}
            endIcon={<ArrowForwardIcon fontSize="small" />}
          >
            Save &#38; Exit
          </Button>
        </Grid>
      );
    } else if (navSkipSaveStatus === 'back') {
      return (
        <Grid item container xs={6} justifyContent="flex-end">
          <Link to="/" className={classes.skipSaveLink}>
            <Button
              className="Nav-exit-btn"
              color="primary"
              variant="contained"
              disableElevation
              startIcon={<ArrowBack fontSize="small" />}
            >
              {domain.peacock ? 'Exit' : 'Back'}
            </Button>
          </Link>
        </Grid>
      );
    }

    return null;
  }

  // Render functions
  // TODO: Make dynamic logo import based on theme value
  const renderThemeLogo = () => (
    <>
      <img
        src={require('../images/nbcu/padman-white-and-yellow-logo.png')}
        alt="Peacock Ad Manager logo"
        className={classes.logoNBCU}
      />
    </>
  );

  const renderFooter = () => (
    <Box className={classes.nbcuFooter}>
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        flex-direction="row"
        style={{ height: '40px' }}
      >
        <Grid
          item
          container
          justifyContent="flex-end"
          alignItems="center"
          xs={6}
        >
          <span className={classes.poweredBy}>Powered by&nbsp;&nbsp;</span>

          <TvsciLogo className={classes.logoFooter} />
        </Grid>
      </Grid>
    </Box>
  );

  const renderHeader = () => (
    <Fragment>
      <CssBaseline />

      <AppBar
        position="absolute"
        className={clsx(classes.appBar, !open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <Grid container alignItems="center">
            <Grid item container xs={6}>
              <Box display="flex">
                {domain.default && (
                  <TvsciLogo
                    alt="tvScientific logo"
                    className={classes.logo}
                  />
                )}

                {domain.peacock && renderThemeLogo()}
              </Box>
            </Grid>
            <NavSkipSave />
          </Grid>
        </Toolbar>
      </AppBar>

      <main className={`${classes.content}`}>
        <div className={classes.appBarSpacer} />
        {children}
      </main>
      {domain.peacock && renderFooter()}
    </Fragment>
  );

  // Conditionally render side nav drawer
  const renderHeaderAuth = () => (
    <Fragment>
      <CssBaseline />
      <StyledDrawer variant="permanent" themeVersion={themeVersion} open>
        <div className={classes.toolbarIcon}></div>

        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          className={clsx('Drawer-nav_wrap', classes.sideNav)}
        >
          <Grid className={classes.innerWrap} item>
            <MainListItems
              showCampaignsLink={showCampaignsLink}
              showReportsLink={showReportsLink}
              showCreativesLink={isInternalUser || isTenantAdmin}
              showTrackingLink={isInternalUser || isTenantAdmin}
            />
          </Grid>

          <Grid className={classes.innerWrap} item>
            <SecondaryListItems
              showBusinessManagerLink={showBusinessManagerLink}
            />
          </Grid>
        </Grid>
      </StyledDrawer>

      <AppBar position="absolute" className={classes.appBar}>
        <Toolbar className={clsx(classes.toolbar, classes.loggedIn)}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Grid container alignItems="center">
                {domain.default && (
                  <TvsciLogo
                    alt="tvScientific logo"
                    className={classes.logo}
                  />
                )}

                {domain.peacock && renderThemeLogo()}
              </Grid>
            </Grid>

            <Grid item>
              <LoginButton />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <main
        className={clsx(
          classes.content,
          domain.peacock ? classes.padding : ''
        )}
      >
        <div className={classes.appBarSpacer} />

        {children}
      </main>

      {domain.peacock && renderFooter()}
    </Fragment>
  );

  return (
    <Root
      className={clsx(
        adContext.theme && `${adContext.theme}-theme`,
        className
      )}
      {...rest}
      themeVersion={themeVersion}
    >
      <Helmet>
        <title>
          {domain.peacock ? 'Peacock Ad Manager' : 'tvScientific'}
        </title>
        <link rel="icon" href={faviconPath} />
      </Helmet>
      {hidenav === 'true' ? renderHeader() : renderHeaderAuth()}
    </Root>
  );
};

AppHeader.propTypes = {
  hidenav: PropTypes.string,
  history: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.elementLike,
  navSkipSaveStatus: PropTypes.string,
  isSaveButtonDisabled: PropTypes.bool,
  theme: PropTypes.string,
  triggerSave: PropTypes.func,
  step: PropTypes.string,
};

export default AppHeader;
