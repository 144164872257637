import { Stack } from '@mui/material';
import PropTypes from 'prop-types';

export const optionType = PropTypes.object;

export const optionalOptionType = PropTypes.oneOfType([
  optionType,
  PropTypes.oneOf([null]),
]);

export const tabType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  hint: PropTypes.string,
});

export const contextType = PropTypes.shape({
  value: PropTypes.string.isRequired,
  stressed: PropTypes.bool.isRequired,
});

export const groupedOptionType = PropTypes.shape({
  groupName: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(optionType),
});

export const dataType = PropTypes.oneOfType([
  PropTypes.arrayOf(optionType),
  PropTypes.arrayOf(groupedOptionType),
]);

export const tableSettings = PropTypes.shape({
  includable: PropTypes.bool,
  separateInclExcl: PropTypes.bool,
});

export const tableType = {
  ...Stack.propTypes,
  selected: PropTypes.arrayOf(optionType).isRequired,
  onChange: PropTypes.func.isRequired,
  dataName: PropTypes.string.isRequired,
  transform: PropTypes.object,
  data: dataType,
  filter: PropTypes.string,
  onFilter: PropTypes.func,
  renderCell: PropTypes.func.isRequired,
  renderGroup: PropTypes.func,
  minRowsAmount: PropTypes.number,
  settings: tableSettings,
  virtualized: PropTypes.bool,
  onIncludeAll: PropTypes.func,
  onExcludeAll: PropTypes.func,
};

export type TableTab = {
  label: string;
  value: string;
  hint?: string;
  settings?: {
    warning?: boolean;
    includable?: boolean;
    separateInclExcl?: boolean;
    showSeparateSwitch?: boolean;
  };
};

export type GroupedData<T, Groups = string> = {
  groupName: Groups;
  data: T[];
};
