import { first, flatMap, isEmpty, isString, last, map } from 'lodash';
import { allValue, ages, incomes } from './constants';
import { isBetween } from '@v2/components/ui/ButtonSlider';
import { BlockOption } from './types';

export const getNumbers = (
  values: string | number[] | number[][]
): number[] =>
  values === allValue
    ? []
    : flatMap(values as number[] | number[][]).sort((a, b) =>
        a > b ? 1 : -1
      );

export const fromArray = (values: string | string[]) =>
  Array.isArray(values) ? values[0] : values;

export const getAllNumbers = (options: BlockOption[]) =>
  getNumbers(
    map(options, 'value').filter(v => !isString(v)) as number[] | number[][]
  );

export const isTheSame = (
  [firstValue, lastValue]: [string | number, string | number],
  [first, last]: [string | number, string | number]
) => {
  return firstValue === first && lastValue === last;
};

export const getOptionsRange = (
  value: string | number[],
  options: BlockOption[]
): string | [number, number] => {
  if (isEmpty(value) || value === allValue) return (value as string) ?? 'All';

  const numbers =
    value[0] === allValue ? getAllNumbers(options) : getNumbers(value);

  return [first(numbers)!, last(numbers)!];
};

const getLabels = (
  [firstValue, lastValue]: [string | number, string | number],
  options: BlockOption[]
) => {
  const firstOption = options.find(v =>
    isBetween(v.value, [firstValue, lastValue])
  ) ??
    first(options) ?? {
      value: allValue,
      label: '',
    };
  const lastOption = options.findLast(v =>
    isBetween(v.value, [firstValue, lastValue])
  ) ??
    last(options) ?? {
      value: allValue,
      label: '',
    };

  const firstLabel = first(firstOption.label.split('-'))?.trim() ?? '';
  const lastLabel = last(lastOption.label.split('-'))?.trim() ?? '';

  return [firstLabel, lastLabel];
};

const isAll = (
  [firstValue, lastValue]: [number, number],
  options: BlockOption<number[]>[]
) => {
  return (
    firstValue <= first(options)!.value[0] &&
    lastValue >= last(options)!.value[1]
  );
};

export const getAgesLabel = (values: number[]) => {
  const numbers = getNumbers(values);

  const withoutAll = ages.slice(1);

  if (
    isEmpty(values) ||
    isAll([first(numbers)!, last(numbers)!], withoutAll)
  ) {
    return 'All Ages';
  }

  if (last(numbers) === last(withoutAll)?.value[1]) {
    return `Ages above ${first(numbers)}`;
  }

  if (first(numbers) === first(withoutAll)?.value[0]) {
    return `Ages below ${last(numbers)}`;
  }

  const [firstLabel, lastLabel] = getLabels(
    [first(numbers)!, last(numbers)!],
    withoutAll
  );

  return `Ages ${firstLabel} - ${lastLabel}`;
};

export const getIncomeLabel = (values: number[]) => {
  const numbers = getNumbers(values);

  const withoutAll = incomes.slice(1);

  if (
    isEmpty(values) ||
    isAll([first(numbers)!, last(numbers)!], withoutAll)
  ) {
    return 'All Household Incomes';
  }

  if (last(numbers) === last(withoutAll)?.value[1]) {
    return `Income above $${first(numbers)}K`;
  }

  if (first(numbers) === first(withoutAll)?.value[0]) {
    return `Income below $${last(numbers)}K`;
  }

  const [firstLabel, lastLabel] = getLabels(
    [first(numbers)!, last(numbers)!],
    withoutAll
  );

  return `Income ${firstLabel} - ${lastLabel}`;
};

export const transformFromAll = (v: string | number[]): number[] =>
  v === allValue ? [] : (v as number[]);
