import { Button, MenuItem, styled, Typography } from '@mui/material';
import { NodeStatus } from './types';
import { nodeStatus } from './constants';

export const StyledMenuItem = styled(MenuItem)<{
  type?: string;
}>(({ theme, type }) => ({
  '&.MuiMenuItem-root': {
    color: type === 'warning' ? theme.palette.red[1] : 'inherit',
  },
}));

export const StyledSubwayNodeButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'status',
})<{
  status: NodeStatus;
}>(({ theme, status }) => ({
  ...theme.typography.body1,
  padding: 0,
  color:
    status === nodeStatus.active
      ? theme.palette.common.black
      : theme.palette.grey[4],
  justifyContent: 'flex-start',
  '&.Mui-disabled': {
    color:
      status === nodeStatus.active
        ? theme.palette.common.black
        : theme.palette.grey[4],
  },
  '&:hover': {
    color: status === nodeStatus.add ? theme.palette.blue[6] : '',
    '& .MuiButton-startIcon': {
      color: status === nodeStatus.add ? theme.palette.blue[6] : '',
    },
  },
  '& .MuiButton-startIcon': {
    marginLeft: 0,
    color:
      status === nodeStatus.active
        ? theme.palette.blue[6]
        : theme.palette.grey[4],
  },
}));

export const StyledTruncableTypography = styled(Typography)<{
  isExpanded: boolean;
}>(({ theme, isExpanded }) => ({
  color: isExpanded ? theme.palette.common['black'] : theme.palette.grey[4],
  lineHeight: 'normal',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontSize: '1.125rem',
  textAlign: 'left',
}));

export const StyledTruncableSubTitle = styled(Typography)<{
  isExpanded: boolean;
}>(({ theme, isExpanded }) => ({
  color: isExpanded ? theme.palette.common['black'] : theme.palette.grey[4],
  fontSize: '0.75rem',
  fontWeight: 400,
  textAlign: 'left',
}));
