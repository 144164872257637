import React, {
  Suspense,
  lazy,
  useState,
  useEffect,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  FormControl,
  ListItemText,
  Paper,
  Divider,
  IconButton,
  styled,
  Popper,
  ClickAwayListener,
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Status, DefaultStatusKeys, StatusDisplayNames } from '@local-types';
import AdvertiserContext from '../../AdvertiserContext';
import DeleteDraftCampaign from '../../DeleteDraftCampaign';
import LoadingSpinner from '../../ui/LoadingSpinner';
import ManageBudget from '../../ManageBudget';
import ModalOverlay from '../../ModalOverlay';
import { useAPI } from '../../hooks/api';
import { useLogRocket } from '../../hooks/logRocket';
import { RoutePaths } from '../../../constants';
import AlertBox from '../../AlertBox';
import { EmbedLookerChart } from '../../EmbedLookerChart';
import { useIntercom } from '../../../helpers/intercomWrapper';
import { getPrimaryOrgId } from '../../../helpers';
import CampaignsTable from './CampaignsTable';
import { classes, StyledAppHeader } from './styles';
import { generatePath } from 'react-router-dom';
import { ErrorBoundary } from '@v2/components/ui/ErrorBoundary';
import {
  useAdvertisers,
  useFlags,
  useLoader,
  useQuery,
  useWizardRedirect,
} from '@hooks';
import { useDomain } from '@hooks/domain';
import ArchiveUnarchive from '@components/ArchiveUnarchive';
import { FilterMenu } from '@components/FilterMenu';
import { useFlags as useLDFlags } from 'launchdarkly-react-client-sdk';
import CampaignsTableV2 from './CampaignsTableV2';
import {
  CampaignsTableContextProvider,
  CampaignsTableContext,
} from './CampaignsTableContext';
import CampaignIdFilter from './CampaignIdFilter';
import CampaignNameFilter from './CampaignNameFilter';
import AdGroupIdFilter from './AdGroupIdFilter';
import AdGroupNameFilter from './AdGroupNameFilter';

const ManageCampaign = lazy(() => import('../../ManageCampaign'));

const PaperStyled = styled(Paper)(() => ({
  overflow: 'hidden',
}));

const filterMenuItems = [
  {
    id: 'details',
    name: 'Details',
    children: [
      {
        id: 'name_id',
        name: 'Name/ID',
        children: [
          {
            id: 'campaign_id',
            name: 'Campaign ID',
            component: CampaignIdFilter,
          },
          {
            id: 'campaign_name',
            name: 'Campaign Name',
            component: CampaignNameFilter,
          },
          {
            id: 'adgroup_id',
            name: 'Ad Group ID',
            component: AdGroupIdFilter,
          },
          {
            id: 'adgroup_name',
            name: 'Ad Group Name',
            component: AdGroupNameFilter,
          },
        ],
      },
    ],
  },
];

const CampaignsPage = props => {
  const adContext = useContext(AdvertiserContext);
  const domain = useDomain();
  const urlQuery = useQuery();
  const { currentAdvertiser } = useAdvertisers();
  const { flags, Flags } = useFlags();

  const isVerticalCampaignFlowEnabled =
    flags[Flags.USER_GETS_VERTICAL_CAMPAIGN];
  const isArchivingEnabled = flags.USER_CAN_ARCHIVE;
  const { campaignsV2Table, fancyTableFiltering } = useLDFlags();

  const { useGet } = useAPI();
  const { isLoading: isPageLoading } = useLoader();

  const [alert, setAlert] = useState(null);
  const [isAlertOpen, setAlertOpen] = useState(true);
  const [currentCampaign, setCurrentCampaign] = useState(null);
  const [isCampaign, setIsCampaign] = useState(false);
  const [isDupeCampaign, setIsDupeCampaign] = useState(false);
  const [isEditBudget, setIsEditBudget] = useState(false);
  const [isDeleteDraft, setIsDeleteDraft] = useState(false);
  const [archiveState, setArchiveState] = useState(null);
  const [statuses, setStatuses] = useState(DefaultStatusKeys);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterOpen, setFilterOpen] = useState(false);
  const [params, setParams] = useState({
    campaignIds: '',
    status: '',
  });

  const regularStatuses = Object.values(Status).filter(
    status => status !== Status.ARCHIVED,
  );
  const regularStatusValues = regularStatuses;
  const { tagUser } = useLogRocket();
  const { showArticle } = useIntercom();
  const { editCampaign } = useWizardRedirect();

  const handleManageBudget = campaign => {
    setCurrentCampaign(campaign);
    setIsEditBudget(true);
  };

  const handleDeleteDraft = campaign => {
    setCurrentCampaign(campaign);
    setIsDeleteDraft(true);
  };

  const handleDuplicateCampaign = async campaign => {
    setCurrentCampaign(campaign);
    setIsDupeCampaign(true);
    setIsCampaign(true);
  };

  const handleDraftCampaign = campaign => {
    if (isVerticalCampaignFlowEnabled) {
      return editCampaign({ campaignId: campaign });
    }

    props.history.push(
      generatePath(RoutePaths.OLD_CAMPAIGN_EDIT, { id: campaign }),
    );
  };

  const handleManageCampaign = campaign => {
    if (isVerticalCampaignFlowEnabled) {
      return editCampaign({ campaignId: campaign.id });
    }

    setCurrentCampaign(campaign);
    setIsCampaign(true);
  };

  const handleArchiveUnarchiveCampaign = campaign => {
    const isArchiving = campaign.status === Status.INACTIVE;
    setArchiveState(isArchiving ? 'archiving' : 'unarchiving');
    setCurrentCampaign(campaign);
  };

  const handleStatusesChange = event => {
    const { value } = event.target;
    const nextStatuses = typeof value === 'string' ? value.split(',') : value;

    // If "Select All" is clicked
    if (nextStatuses[nextStatuses.length - 1] === 'all') {
      // If all regular statuses are currently selected, clear them
      // Otherwise, select all regular statuses
      const hasAllRegularStatuses = regularStatusValues.every(status =>
        statuses.includes(status),
      );

      // Keep the archived status selection
      const keepArchivedStatus = statuses.includes(Status.ARCHIVED)
        ? [Status.ARCHIVED]
        : [];

      setStatuses(
        hasAllRegularStatuses
          ? keepArchivedStatus
          : [...regularStatusValues, ...keepArchivedStatus],
      );
      return;
    }

    setStatuses(nextStatuses);
  };

  const handleCloseDeleteDraft = () => {
    setIsDeleteDraft(false);
  };

  const handleCloseBudget = () => {
    setIsEditBudget(false);
    setCurrentCampaign(null);
  };

  const handleCloseArchiveUnarchive = (refreshCampaigns) => {
    setArchiveState(null);
    setCurrentCampaign(null);
    refreshCampaigns();
  };

  useEffect(() => {
    setParams(prev => ({
      ...prev,
      status: statuses.join(','),
    }));
  }, [statuses]);

  useEffect(() => {
    // Tag user sso id, name and email in Log Rocket
    tagUser();
    getAlerts();
  }, []);

  useEffect(() => {
    if (isVerticalCampaignFlowEnabled) {
      urlQuery.set('org', getPrimaryOrgId(currentAdvertiser.primary_org));
      urlQuery.set('adAccount', currentAdvertiser?.id);
    }
  }, [
    isVerticalCampaignFlowEnabled,
    urlQuery,
    currentAdvertiser?.id,
    currentAdvertiser?.primary_org,
  ]);

  async function getAlerts() {
    const alerts = await useGet('/alerts');
    setAlert(alerts.activeAlert !== null ? alerts : null);
  }

  const goLiveMessage = {
    title: 'NOTICE',
    message:
      'This Ad Account needs to be associated with a valid Billing Account before any Campaigns will go live! ',
    type: 'MAJOR',
    closeable: false,
    isAlertOpen: true,
  };

  const campaignsTableProps = {
    params,
    setIsDupeCampaign,
    handleManageBudget,
    handleDeleteDraft,
    handleDuplicateCampaign,
    handleDraftCampaign,
    handleManageCampaign,
    handleArchiveUnarchiveCampaign,
    urlQuery,
  };

  const handleFilterClick = event => {
    setAnchorEl(event.currentTarget);
    setFilterOpen(prev => !prev);
  };

  const handleFilterClose = () => {
    setFilterOpen(false);
  };

  const handleFilterSubmit = newParams => {
    setParams(prev => ({
      ...prev,
      ...newParams,
    }));
  };

  return (
    <StyledAppHeader history={props.history}>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          {alert !== null && (
            <Grid item xs={12}>
              <AlertBox
                {...alert.activeAlert}
                isAlertOpen={isAlertOpen}
                closeAlert={setAlertOpen}
              />
            </Grid>
          )}
          {!adContext?.billing_account_is_valid && (
            <Grid item xs={12}>
              <AlertBox
                {...goLiveMessage}
                isAlertOpen={isAlertOpen}
                closeAlert={setAlertOpen}
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => showArticle('8516335')}
                  >
                    Click Here for Help!
                  </Button>
                }
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <PaperStyled>
              <EmbedLookerChart dashboard="CAMPAIGN" />
            </PaperStyled>
          </Grid>
        </Grid>
      </Container>

      <ErrorBoundary fallback={<h2>Could not fetch campaigns.</h2>}>
        <Suspense>
          <CampaignsTableContextProvider {...campaignsTableProps}>
            <CampaignsTableContext.Consumer>
              {({
                handlePauseActive,
                handleSaveCampaign,
                handleDeleteCampaignComplete,
                refreshCampaigns,
              }) => (
                <Box
                  border={1}
                  borderColor="grey.300"
                  p={6}
                  pt={4}
                  m={4}
                  borderRadius="20px"
                  data-testid="campaigns-card"
                >
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Typography
                        variant="h6"
                        component="div"
                        data-testid="active-campaigns-header"
                      >
                        Active Campaigns
                      </Typography>
                    </Grid>

                    <Grid item>
                      {fancyTableFiltering && (
                        <>
                          <IconButton
                            onClick={handleFilterClick}
                            size="small"
                            sx={{ ml: 1 }}
                            aria-label="open filter menu"
                          >
                            <FilterListIcon />
                          </IconButton>

                          {filterOpen && (
                            <Popper
                              open={filterOpen}
                              anchorEl={anchorEl}
                              placement="bottom-end"
                              sx={{ width: 'calc(66.67vw)' }}
                            >
                              <ClickAwayListener
                                onClickAway={handleFilterClose}
                              >
                                <FilterMenu
                                  data={params}
                                  items={filterMenuItems}
                                  onClose={handleFilterClose}
                                  onSubmit={handleFilterSubmit}
                                />
                              </ClickAwayListener>
                            </Popper>
                          )}
                        </>
                      )}

                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <InputLabel id="status-multiple-checkbox-label">
                          Status
                        </InputLabel>
                        <Select
                          variant="standard"
                          multiple
                          color="secondary"
                          id="status-multiple-checkbox"
                          labelId="status-multiple-checkbox-label"
                          label="Status"
                          value={statuses}
                          onChange={handleStatusesChange}
                          renderValue={selected => {
                            const hasAllRegularStatuses =
                              regularStatusValues.every(value =>
                                selected.includes(value),
                              );
                              const hasArchivedStatus = selected.includes(Status.ARCHIVED);
                              const selectedValues = selected
                                .map(status => StatusDisplayNames[status])
                                .join(', ');

                            const displayText = hasAllRegularStatuses
                              ? hasArchivedStatus
                                ? 'All, Archived'
                                : 'All'
                              : selectedValues;

                            return `Showing [${displayText}] status`;
                          }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 48 * 6.5 + 8,
                                width: 250,
                              },
                            },
                          }}
                        >
                          <MenuItem value="all">
                            <Checkbox
                              checked={regularStatusValues.every(status =>
                                statuses.includes(status)
                              )}
                              indeterminate={
                                regularStatusValues.some(status =>
                                  statuses.includes(status)
                                ) &&
                                !regularStatusValues.every(status =>
                                  statuses.includes(status)
                                )
                              }
                            />
                            <ListItemText primary="Select All" />
                          </MenuItem>
                          {regularStatuses.map(status => (
                            <MenuItem key={status} value={status}>
                              <Checkbox checked={statuses.includes(status)} />
                              <ListItemText primary={StatusDisplayNames[status]} />
                            </MenuItem>
                          ))}
                          {isArchivingEnabled && (
                            <Box>
                              <Divider
                                sx={{
                                  borderBottomWidth: '2px',
                                  borderBottomColor: 'grey.main',
                                  marginY: '8px',
                                }}
                                flexItem
                              />
                              <MenuItem
                                key={Status.ARCHIVED}
                                value={Status.ARCHIVED}
                                onClick={() => {
                                  const nextStatuses = statuses.includes(Status.ARCHIVED)
                                    ? statuses.filter(status => status !== Status.ARCHIVED)
                                    : [...statuses, Status.ARCHIVED];
                                  setStatuses(nextStatuses);
                                }}
                                data-testid="archived-status"
                              >
                                <Checkbox checked={statuses.includes(Status.ARCHIVED)} />
                                <ListItemText primary={StatusDisplayNames[Status.ARCHIVED]} />
                              </MenuItem>
                            </Box>
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  {campaignsV2Table ? (
                    <CampaignsTableV2 />
                  ) : (
                    <CampaignsTable
                      {...campaignsTableProps}
                      handlePauseActive={handlePauseActive}
                    />
                  )}

                  {isEditBudget && (
                    <ManageBudget
                      isModal
                      isOpen={isEditBudget}
                      currentCampaign={currentCampaign}
                      onClose={handleCloseBudget}
                      setHasSaved={handleSaveCampaign}
                    />
                  )}

                  {isCampaign && (
                    <ModalOverlay
                      className={classes.modal}
                      isOpen={isCampaign}
                      onClose={() => {
                        setIsCampaign(false);
                        setIsDupeCampaign(false);
                      }}
                    >
                      <Suspense fallback={<LoadingSpinner />}>
                        <ManageCampaign
                          showTestIncrementality={
                            domain.default ||
                            (domain.peacock &&
                              !!currentCampaign.experiment_type)
                          }
                          showType={domain.default}
                          isDupeCampaign={isDupeCampaign}
                          setHasSaved={handleSaveCampaign}
                          setIsCampaign={setIsCampaign}
                          setIsDupeCampaign={setIsDupeCampaign}
                          currentCampaign={currentCampaign}
                        />
                      </Suspense>
                    </ModalOverlay>
                  )}

                  {isDeleteDraft && (
                    <DeleteDraftCampaign
                      isModal
                      isOpen={isDeleteDraft}
                      campaign={currentCampaign}
                      onClose={handleCloseDeleteDraft}
                      onDelete={handleDeleteCampaignComplete}
                    />
                  )}

                  {isPageLoading && (
                    <Box
                      width="100%"
                      height="100%"
                      className={classes.pageLoader}
                    >
                      <CircularProgress color="secondary" />
                    </Box>
                  )}

            {archiveState && (
              <ArchiveUnarchive
                isOpen={!!archiveState}
                item={currentCampaign}
                onClose={() => handleCloseArchiveUnarchive(refreshCampaigns)}
                isArchiving={archiveState === 'archiving'}
                itemType="Campaign"
              />
            )}
                </Box>
              )}
            </CampaignsTableContext.Consumer>
          </CampaignsTableContextProvider>
        </Suspense>
      </ErrorBoundary>
    </StyledAppHeader>
  );
};

CampaignsPage.propTypes = {
  history: PropTypes.object,
};

export default CampaignsPage;
