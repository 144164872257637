const DEFAULT = 'default';
const NBCU = 'nbcu';
const DEFAULT_V2 = 'default_v2';
const PADMAN_V2 = 'padman_v2';

export const Themes = {
  DEFAULT,
  NBCU,
  DEFAULT_V2,
  PADMAN_V2,
} as const;

export const ThemeNames = {
  [Themes.DEFAULT]: 'defaultTheme',
  [Themes.NBCU]: 'nbcuTheme',
  [Themes.DEFAULT_V2]: 'defaultTheme_v2',
  [Themes.PADMAN_V2]: 'padmanTheme_v2',
} as const;
