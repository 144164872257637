import React, { Fragment, useContext, useEffect, useMemo, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  ButtonBase,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers-pro';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useSnackbar } from 'notistack';
import numeral from 'numeral';

import { ClickableArea } from './styledComponents';
import { convertToTime } from './util';
import { useAPI } from './hooks/api';
import { useCopy, useLoader, useUser } from './hooks';
import { useSaveExit } from './hooks/saveExit';
import CampaignFooter from './CampaignFooter';
import Title from './Title';
import AdvertiserContext from './AdvertiserContext';
import freqCapIcon from '../images/icon-config-freqcaps.png';
import { Themes } from '../constants';
import { BidStrategy } from './BidStrategy';
import { useUtil } from '../components/hooks/util'
import moment from 'moment';
import { useDate } from './hooks/date';
import { useCampaigns } from './hooks/campaigns';

const PREFIX = 'NameAdGroup';

const classes = {
  bid: `${PREFIX}-bid`,
  divider: `${PREFIX}-divider`,
  tooltip: `${PREFIX}-tooltip`,
  cap: `${PREFIX}-cap`,
  datePicker: `${PREFIX}-datePicker`,
  freqCap: `${PREFIX}-freqCap`,
  freqCapsBtn: `${PREFIX}-freqCapsBtn`,
  freqCapWrap: `${PREFIX}-freqCapWrap`,
  tooltipWidth: `${PREFIX}-tooltipWidth`
};

const Root  = styled("div")(({ theme }) => ({
  [`& .${classes.bid}`]: {
    ...theme.typography.body2,
    fontSize: '0.725rem',
    lineHeight: '1.025rem',
    margin: 0,
  },

  [`& .${classes.divider}`]: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.tooltip}`]: {
    backgroundColor: `#f2f3f5`,
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
    maxWidth: 180,
  },

  [`& .${classes.cap}`]: {
    color: '#181c20',
    fontSize: '.925rem',
    fontWeight: 400,
    lineHeight: '1.4rem',
  },

  [`& .${classes.datePicker}`]: {
    '& .MuiIconButton-root': {
      height: 30,
      width: 30,
    },

    '& .MuiSvgIcon-root': {
      width: '0.8em',
      height: '0.8em',
    }
  },

  [`& .${classes.freqCap}`]: {
    color: '#5c6b73',
    fontSize: '0.75rem',
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.freqCapsBtn}`]: {
    width: '90%',
    textAlign: 'left',
  },

  [`& .${classes.freqCapWrap}`]: {
    border: '1px solid rgb(224, 224, 224)',
    padding: theme.spacing(3),
    borderRadius: 5,
    textAlign: 'left',
    width: '100%',
  },

  [`& .${classes.tooltipWidth}`]: {
    maxWidth: 180,
  }
}));


const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

const Copies = {
  [Themes.DEFAULT]: {
    INTRO: null,
    DELIVERY_TIMEZONE_LABEL: 'Delivery Timezone',
    CONFIG_FREQUENCY_CAP_TOOLTIP: null,
    FREQUENCY_CAP_LABEL: 'Configure frequency cap',
  },
  [Themes.NBCU]: {
    INTRO: 'Enter a name for this ad group. An ad group is a subset of a campaign. You should create a different ad group for each audience you plan to target.',
    DELIVERY_TIMEZONE_LABEL: 'Delivery Time Zone',
    CONFIG_FREQUENCY_CAP_TOOLTIP: 'If selecting a frequency cap for this ad group, it must be less than or equal to the one selected for the overall campaign.',
    FREQUENCY_CAP_LABEL: 'Configure Frequency Cap',
  },
};

///////////////////////////////
// NAME AD GROUP COMPONENT
///////////////////////////////
const NameAdGroup = props => {

  const history = useHistory();
  const { isLoading, setIsLoading } = useLoader();
  const { usePatch } = useAPI();
  const { saveProgress } = useSaveExit();
  const Copy = useCopy(Copies);
  const adContext = useContext(AdvertiserContext);
  const { user } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const { useDebouncedValue, validateDecimals } = useUtil();
  const { getFormattedDateTimes } = useDate();
  const { campaign } = useCampaigns();

  const {
    currentAdGroup,
    combinedTargetingData,
    cpmRange,
    freqCaps,
    dayParts,
    deliveryTime,
    currentDayPartsCopy = 'Configure day-parting (optional)',
    setIsFreqCaps,
    setIsDayParting,
    save,
    triggerSave,
    setDeliveryTime,
    setStep,
    adjustRecommendedBid,
  } = props;

  const [adGroup, setAdGroup] = useState(currentAdGroup?.name || '');
  const [bidStrategy, setBidStrategy] = useState({
    bid_strategy: currentAdGroup?.bid_strategy || null,
    bid_strategy_event: currentAdGroup?.bid_strategy_event || null,
    bid_strategy_target: currentAdGroup?.bid_strategy_target || null,
    cpm: currentAdGroup?.cpm || null
  });
  const [adjustedCPM, setAdjustedCPM] = useState(0);
  const bidStrategyRef = useRef(null);

  const [noEndDate, setNoEndDate] = useState(true);

  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedEndTime, setSelectedEndTime] = useState(null);

  useEffect(() => {
    if (currentAdGroup?.start_date) {
      setSelectedStartDate(moment(currentAdGroup.start_date).tz("America/New_York"));
      setSelectedStartTime(moment(currentAdGroup.start_date).tz("America/New_York"));
    }

    if (currentAdGroup?.end_date) {
      setNoEndDate(false)
      setSelectedEndDate(moment(currentAdGroup.end_date).tz("America/New_York"));
      setSelectedEndTime(moment(currentAdGroup.end_date).tz("America/New_York"));
    }
  }, [currentAdGroup]);


  useEffect(() => {
    if (noEndDate && !currentAdGroup?.end_date) {
      const today = moment().tz("America/New_York").endOf('day');
      setSelectedEndDate(today);
      setSelectedEndTime(today);
    }
  }, [noEndDate, currentAdGroup]);

  const handleNoEndDateChange = () => {
    setNoEndDate(!noEndDate);
  };

  const handleBidStategyChange = (s) => {
    setBidStrategy(s);
  };

  const handleUpdateStartDate = date => {
    setSelectedStartDate(date || '');
  };

  const handleUpdateStartTime = date => {
    setSelectedStartTime(date || '');
  };

  const handleUpdateEndDate = date => {
    setSelectedEndDate(date || '');
  };

  const handleUpdateEndTime = date => {
    setSelectedEndTime(date || '');
  };

  const getMaxRecBid = async (cpm) => {
    if (!cpm) {
      return
    }
    try {
      const response = await usePatch(`/lineitems/${currentAdGroup.id}/`, { cpm })
      const { adjusted_cpm } = response.data
      setAdjustedCPM(adjusted_cpm)
    } catch (error) {
      console.error(error)
      enqueueSnackbar("Error getting the Max CPM Bid", {
        variant: 'error',
      });
    }
  }

  const debouncedMaxBid = useDebouncedValue(bidStrategy.cpm, 500);

  const isInternalUser = useMemo(
    () => !!user && user.is_tvsci_employee
    , [user]);

  useEffect(() => {
    props.setInventoryOverlay(false);
  }, []);

  useEffect(() => {
    if (currentAdGroup != null && currentAdGroup.name != null) {
      setAdGroup(currentAdGroup.name);
    }

    if (currentAdGroup != null && currentAdGroup.cpm != null) {
      setBidStrategy.cpm = currentAdGroup.cpm;
    }
  }, [currentAdGroup]);

  useEffect(() => {
    saveProgress(save, 'NameAdGroup', handleSave, triggerSave);
  }, [save]);

  useEffect(() => {
    if (
      currentAdGroup &&
      currentAdGroup.creatives &&
      currentAdGroup.creatives.length > 0
    ) {
      props.updateBreadcrumbs('targeting', 75, 'creative', 50);
    } else {
      props.updateBreadcrumbs('targeting', 75, 'creative', -1);
    }
  }, []);

  useEffect(() => {
    if (isInternalUser && validateDecimals(debouncedMaxBid, 2)) {
      getMaxRecBid(debouncedMaxBid);
    }
  }, [debouncedMaxBid, isInternalUser]);

  const rangeText = useMemo(() => {
    const RECOMMENCED_ADJUSTMENT = 2.5;

    let [min, max] = cpmRange || [];
    if (!min || !max) return '';

    if (Math.ceil(min) === Math.ceil(max)) {
      if (adjustRecommendedBid) min = Number(min) + RECOMMENCED_ADJUSTMENT;
      return `${numeral(Math.ceil(min)).format('$0,0')}+`;
    }

    if (adjustRecommendedBid) max = Number(max) + RECOMMENCED_ADJUSTMENT;
    return `${numeral(min).format('$0,0')} - ${numeral(Math.ceil(max)).format(
      '$0,0'
    )}+`;
  }, [cpmRange, adjustRecommendedBid]);


  const handleBack = () => {
    // Reverse breadcrumbs status
    props.setStep('Inventory');
    props.updateBreadcrumbs('targeting', 50);
  };


  const handleFormChange = (event) => {
    // TODO: update this to use react-hook-form so we validate before posting to BE
    setBidStrategy({
      ...bidStrategy,
      [event.target.name]: event.target.value
    });
  };

  const handleSave = async () => {
    setIsLoading(true);

    const { utcStartDateTime, utcEndDateTime } = getFormattedDateTimes(selectedStartDate, selectedStartTime, selectedEndDate, selectedEndTime, noEndDate);

    let dataObj = {
      name: adGroup,
      start_date: utcStartDateTime,
      end_date: utcEndDateTime,
      freq_caps: freqCaps,
      dayparting: dayParts,
      tvsciq: adContext.tvsciq || 'DISABLED',
      targeting: JSON.stringify({
        ...combinedTargetingData,
        timezone: deliveryTime,
      }),
    };

    const { bid_strategy, bid_strategy_event, bid_strategy_target, cpm } = bidStrategy;

    if (shouldShowBidStrategy) {
      dataObj = {
        ...dataObj,
        bid_strategy,
        bid_strategy_event: bid_strategy_event || null,
        bid_strategy_target: bid_strategy_target || null,
        cpm: cpm || null,
      }
    } else {
      dataObj.cpm = cpm;
    }

    try {
      const response = await usePatch(
        `/lineitems/${props.currentAdGroup.id}/`,
        dataObj
      );

      setIsLoading(false);

      if (response) {
        props.handleCurrentAdGroup({
          ...response.data,
          ...dataObj,
          targeting: JSON.parse(dataObj.targeting),
        });

        // Handle Save & Exit button click
        if (save && save.step && save.step === 'NameAdGroup') {
          if (save.exit) {
            history.push('/');
          }
        }

        // Handle next button click
        if (!save.exit) {
          // props.setIsAdGroup(false);
          props.updateBreadcrumbs('targeting', 100, 'creative', 0);
          setStep('CreativeUpload');
        }
      }

      return response;
    } catch (error) {
      console.error('Error saving NameAdGroup', error);
      setIsLoading(false);
      return error;
    }
  };

  const shouldShowBidStrategy = adContext?.bidstrategy_set?.length > 0 && adContext.tvsciq !== 'DISABLED';

  const canSaveStep = () => {
    return (
      adGroup.length >= 3 &&
      ((!shouldShowBidStrategy && bidStrategy.cpm > 0) || bidStrategyRef.current?.isValid(bidStrategy))
    );
  };

  return (
    <Root>
      <Grid
        container
        justifyContent="space-between"
        direction="column"
        sx={{
          width: "100%",
          height: "100%"
        }}
      >
        <Grid item>
          <Title>Name Ad Group</Title>
          {Copy.INTRO && <p>{Copy.INTRO}</p>}
          <Divider className={classes.divider} />
        </Grid>

        <Grid
          item
          container
          justifyContent="space-between"
          spacing={5}
        >
          <Grid
            item
            container
            direction="column"
            spacing={2}
            xs={6}
          >
            <Grid item>
              <TextField
                color="secondary"
                fullWidth
                id="target-profile-name"
                label="Name"
                placeholder="Enter a name for your ad group"
                variant="outlined"
                value={adGroup}
                onChange={event => setAdGroup(event.target.value)}
              />
            </Grid>

            <Grid item>
              <Box mt={1}>
                <TextField
                  select
                  color="secondary"
                  fullWidth
                  label={Copy.DELIVERY_TIMEZONE_LABEL}
                  variant="outlined"
                  value={deliveryTime}
                  onChange={evt => setDeliveryTime(evt.target.value)}
                >
                  <MenuItem value="est">
                    Eastern Time (GMT-05:00)
                  </MenuItem>

                  <MenuItem value="user">
                    Viewers&apos; Local Time
                  </MenuItem>
                </TextField>
              </Box>
            </Grid>

            <Grid item container justifyContent="flex-end">
              <FormControlLabel
                control={
                  <Switch
                    checked={noEndDate}
                    onChange={handleNoEndDateChange}
                    name="noEndDate"
                    color="secondary"
                    size="small"
                  />
                }
                label="No end date"
              />
            </Grid>

            <Grid item>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <DatePicker
                    className={classes.datePicker}
                    disableToolbar
                    inputFormat="MM/dd/yyyy"
                    margin="normal"
                    inputVariant="outlined"
                    label="Start Date"
                    data-testid="start-date-picker"
                    id="date-picker-inline"
                    value={selectedStartDate}
                    onChange={handleUpdateStartDate}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={6} sx={{ position: 'relative' }}>
                  {noEndDate && <ClickableArea onClick={handleNoEndDateChange} />}
                  <DatePicker
                    maxDate={campaign?.end_date ? moment(campaign.end_date) : undefined}
                    className={classes.datePicker}
                    disableToolbar
                    variant="inline"
                    inputFormat="MM/dd/yyyy"
                    margin="normal"
                    inputVariant="outlined"
                    label="End Date"
                    data-testid="end-date-picker"
                    id="date-picker-inline-end"
                    disabled={noEndDate}
                    value={noEndDate ? null : selectedEndDate}
                    minDate={selectedStartDate}
                    onChange={handleUpdateEndDate}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TimePicker
                    className={classes.datePicker}
                    margin="normal"
                    inputVariant="outlined"
                    label="Start Time (ET)"
                    id="time-picker"
                    value={selectedStartTime}
                    onChange={handleUpdateStartTime}
                    KeyboardButtonProps={{
                      'aria-label': 'change start time',
                    }}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={6} sx={{ position: 'relative' }}>
                  {noEndDate && <ClickableArea onClick={handleNoEndDateChange} />}
                  <TimePicker
                    className={classes.datePicker}
                    disabled={noEndDate}
                    margin="normal"
                    inputVariant="outlined"
                    label="End Time (ET)"
                    id="time-picker-end"
                    value={noEndDate ? null : selectedEndTime}
                    onChange={handleUpdateEndTime}
                    KeyboardButtonProps={{
                      'aria-label': 'change end time',
                    }}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>

            {!shouldShowBidStrategy && (
              <Grid item container>
                <Grid item xs={6}>
                  <TextField
                    color="secondary"
                    name="cpm"
                    label="Max CPM Bid"
                    value={bidStrategy.cpm}
                    onChange={handleFormChange}
                    fullWidth
                    type="number"
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      inputProps: { min: 0, step: .01 },
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Grid container justifyContent="flex-start">
                    {cpmRange?.length === 2 &&
                      cpmRange[0] > 0 &&
                      cpmRange[1] > 0 && (
                        <>
                          <Grid item xs={2}>
                            <Tooltip
                              title="Max bids can be up to 10% higher due to auction mechanics."
                              arrow
                              classes={{ tooltip: classes.tooltipWidth }}
                            >
                              <InfoOutlinedIcon fontSize="small" />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={10}>
                            <p className={classes.bid}>
                              The bid range for your inventory selection is{' '}
                              <br />
                              {rangeText}
                            </p>

                            {isInternalUser && (
                              <p className={classes.bid}>
                                Max CPM bid sent to BW <br />
                                {numeral(adjustedCPM).format('$0,0')}
                              </p>
                            )}
                          </Grid>
                        </>
                      )}
                  </Grid>
                </Grid>
              </Grid>
            )}
            {shouldShowBidStrategy && (
              <Grid item>
                <Box mb={2}>
                  <Typography variant="h5">
                    Advanced Optimization Options
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  mb={2}
                >
                  <Grid container item xs={12} alignItems="center">
                    <Button
                      className="freq-cap-btn"
                      onClick={() => setIsFreqCaps(true)}
                      startIcon={<img src={freqCapIcon} alt="Configure frequency cap icon" width={17} />}
                    >
                      <Typography className={classes.freqCap}>
                        {Copy.FREQUENCY_CAP_LABEL}
                      </Typography>
                    </Button>

                    {Copy.CONFIG_FREQUENCY_CAP_TOOLTIP && (
                      <Tooltip
                        classes={{ tooltip: classes.tooltip }}
                        title={Copy.CONFIG_FREQUENCY_CAP_TOOLTIP}
                      >
                        <InfoOutlinedIcon fontSize="small" />
                      </Tooltip>
                    )}
                  </Grid>

                  <ButtonBase
                    disableRipple
                    onClick={() => setIsFreqCaps(true)}
                    className={classes.freqCapsBtn}
                  >
                    {freqCaps && freqCaps.length > 0 && (
                      <Box my={1} className={classes.freqCapWrap}>
                        {freqCaps.map(f => (
                          <Typography key={f.fctype} className={classes.cap}>
                            {`${f.impressions} ads every ${f.duration
                            } ${f.fctype.toLowerCase()}(s)`}
                          </Typography>
                        ))}
                      </Box>
                    )}
                  </ButtonBase>
                </Box>

                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <Button
                    className="freq-cap-btn"
                    onClick={() => setIsDayParting(true)}
                    startIcon={<img src={freqCapIcon} alt="Configure frequency cap icon" width={17} />}
                  >
                    <Typography className={classes.freqCap}>
                      {currentDayPartsCopy}
                    </Typography>
                  </Button>

                  {dayParts.length > 0 &&
                    <ButtonBase
                      className={classes.freqCapsBtn}
                      p={0}
                      onClick={() => setIsDayParting(true)}
                    >
                      <Box my={1} className={classes.freqCapWrap}>
                        {dayParts.map((part, index) =>
                          <Typography key={index} className={classes.cap}>
                            {days.filter(d => part.days[d.toLowerCase()].selected).join(', ')}

                            {` | `}

                            {`${convertToTime(part.time[0], -12, 12)}-
                            ${convertToTime(part.time[1], -12, 12)}`}
                          </Typography>)}
                      </Box>
                    </ButtonBase>}
                </Box>
              </Grid>
            )}
          </Grid>

          <Grid item container xs={6}>
            {!shouldShowBidStrategy && (
              <Grid item>
                <Box mb={2}>
                  <Typography variant="h5">
                    Advanced Optimization Options
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  mb={2}
                >
                  <Grid container item xs={12} alignItems="center">
                    <Button
                      className="freq-cap-btn"
                      onClick={() => setIsFreqCaps(true)}
                      startIcon={<img src={freqCapIcon} alt="Configure frequency cap icon" width={17} />}
                    >
                      <Typography className={classes.freqCap}>
                        {Copy.FREQUENCY_CAP_LABEL}
                      </Typography>
                    </Button>

                    {Copy.CONFIG_FREQUENCY_CAP_TOOLTIP && (
                      <Tooltip
                        classes={{ tooltip: classes.tooltip }}
                        title={Copy.CONFIG_FREQUENCY_CAP_TOOLTIP}
                      >
                        <InfoOutlinedIcon fontSize="small" />
                      </Tooltip>
                    )}
                  </Grid>

                  <ButtonBase
                    disableRipple
                    onClick={() => setIsFreqCaps(true)}
                    className={classes.freqCapsBtn}
                  >
                    {freqCaps && freqCaps.length > 0 && (
                      <Box my={1} className={classes.freqCapWrap}>
                        {freqCaps.map(f => (
                          <Typography key={f.fctype} className={classes.cap}>
                            {`${f.impressions} ads every ${f.duration
                            } ${f.fctype.toLowerCase()}(s)`}
                          </Typography>
                        ))}
                      </Box>
                    )}
                  </ButtonBase>
                </Box>

                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <Button
                    className="freq-cap-btn"
                    onClick={() => setIsDayParting(true)}
                    startIcon={<img src={freqCapIcon} alt="Configure frequency cap icon" width={17} />}
                  >
                    <Typography className={classes.freqCap}>
                      {currentDayPartsCopy}
                    </Typography>
                  </Button>

                  {dayParts.length > 0 &&
                    <ButtonBase
                      className={classes.freqCapsBtn}
                      p={0}
                      onClick={() => setIsDayParting(true)}
                    >
                      <Box my={1} className={classes.freqCapWrap}>
                        {dayParts.map((part, index) =>
                          <Typography key={index} className={classes.cap}>
                            {days.filter(d => part.days[d.toLowerCase()].selected).join(', ')}

                            {` | `}

                            {`${convertToTime(part.time[0], -12, 12)}-
                        ${convertToTime(part.time[1], -12, 12)}`}
                          </Typography>)}
                      </Box>
                    </ButtonBase>}
                </Box>
              </Grid>
            )}

            {shouldShowBidStrategy && (
              <Grid item>
                <BidStrategy
                  ref={bidStrategyRef}
                  adjustRecommendedBid={adjustRecommendedBid}
                  handleBidStategyChange={handleBidStategyChange}
                  isInternalUser={isInternalUser}
                  cpmRange={cpmRange}
                  adGroup={currentAdGroup}
                  bidStrategy={bidStrategy}
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        <CampaignFooter
          isDisabled={!canSaveStep()}
          isLoading={isLoading}
          back={'Inventory'}
          next={'Upload Creative'}
          onBack={handleBack}
          onNext={() => {
            setIsLoading(true);
            triggerSave('NameAdGroup', false, 'CreativeUpload');
          }}
          page={4}
        />
      </Grid>
    </Root >
  );
};

NameAdGroup.propTypes = {
  handleCurrentAdGroup: PropTypes.func,
  updateBreadcrumbs: PropTypes.func,
  noEndDate: PropTypes.bool,
  selectedEndDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  selectedEndTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  selectedStartDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  selectedStartTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  setIsAdGroup: PropTypes.func,
  setStep: PropTypes.func,
  setIsDayParting: PropTypes.func,
  currentAdGroup: PropTypes.object,
  cpmRange: PropTypes.array,
  save: PropTypes.object,
  triggerSave: PropTypes.func,
  adjustRecommendedBid: PropTypes.bool,
  setInventoryOverlay: PropTypes.func,
  freqCaps: PropTypes.array,
  dayParts: PropTypes.array,
  currentDayPartsCopy: PropTypes.string,
  setIsFreqCaps: PropTypes.func,
  deliveryTime: PropTypes.string,
  setDeliveryTime: PropTypes.func,
  combinedTargetingData: PropTypes.object,
};

export default NameAdGroup;
