import React, { forwardRef } from 'react';

import { RadioGroup, Stack, Typography } from '@mui/material';
import { useOptionsAccordion } from './hooks/useOptionsAccordion';
import { HeaderBoxStyled } from './styles';
import { TvSciQIconWithText } from './TvSciQIconWithText';
import type { OptionsAccordionProps } from './types';
import { Accordion } from './Accordion/Accordion';

export const OptionsAccordion = forwardRef<
  HTMLDivElement,
  OptionsAccordionProps
>(
  (
    {
      name,
      trackingIsSetUp,
      headerTitle,
      options,
      onChange,
      value,
      ...props
    },
    ref
  ) => {
    const { isPeacockDomain, change, lockedOptionsExpandedStates } =
      useOptionsAccordion({
        value,
        options,
        onChange,
      });

    return (
      <Stack spacing={2}>
        <HeaderBoxStyled>
          <Typography variant="body1" color="common.white" flexGrow={1}>
            {headerTitle}
          </Typography>
          {!isPeacockDomain && (
            <TvSciQIconWithText data-testid="options-accordion-header-tvsci-icon" />
          )}
        </HeaderBoxStyled>
        <RadioGroup
          ref={ref}
          name={name}
          value={value}
          onChange={change}
          {...props}
        >
          {options.map(option => {
            return (
              <Accordion
                trackingIsSetUp={trackingIsSetUp}
                key={option.value}
                onClick={e => {
                  if (option.locked) {
                    change(e, option.value);
                  }
                }}
                expanded={
                  value === option.value ||
                  (lockedOptionsExpandedStates[option.value] ?? false)
                }
                option={option}
                data-testid={`options-accordion-${option.value}`}
                elevation={5}
                slotProps={{ transition: { unmountOnExit: true } }}
              />
            );
          })}
        </RadioGroup>
      </Stack>
    );
  }
);

OptionsAccordion.displayName = 'OptionsAccordion';
