import { Stack } from '@mui/material';
import React from 'react';
import { AudienceBlock } from './AudienceBlock';
import { ages, genders, incomes } from './constants';
import {
  fromArray,
  getAgesLabel,
  getIncomeLabel,
  getOptionsRange,
  transformFromAll,
} from './utils';
import type { AudienceElementProps, AudienceElementValue } from './types';
import type { Keys } from '@local-types/general';
import { LabelStyled } from './styles';

export const AudienceElement = ({
  value,
  onChange,
  ...props
}: AudienceElementProps) => {
  const handleChange =
    (key: Keys<AudienceElementValue>) =>
    (changedValue: string | number[]) => {
      onChange({
        ...value,
        [key]: changedValue,
      });
    };

  return (
    <Stack spacing={2} {...props}>
      <AudienceBlock
        label={v => (
          <LabelStyled>
            {genders.find(g => g.value === v)?.label ?? 'All genders'}
          </LabelStyled>
        )}
        type="gender"
        onChange={handleChange('gender')}
        options={genders}
        value={fromArray(value.gender)}
        multiple={false}
        highlightFirstItem={true}
      />
      <AudienceBlock
        type="age"
        label={v => (
          <LabelStyled>{getAgesLabel(transformFromAll(v))}</LabelStyled>
        )}
        onChange={v => {
          handleChange('age')(getOptionsRange(v, ages.slice(1)));
        }}
        options={ages}
        value={transformFromAll(value.age)}
        multiple
        highlightFirstItem={true}
      />
      <AudienceBlock
        type="income"
        label={v => (
          <LabelStyled>{getIncomeLabel(transformFromAll(v))}</LabelStyled>
        )}
        onChange={v => {
          handleChange('income')(getOptionsRange(v, incomes.slice(1)));
        }}
        options={incomes}
        value={transformFromAll(value.income)}
        multiple
        highlightFirstItem={true}
      />
    </Stack>
  );
};
