import { Stack, Typography, styled } from '@mui/material';
import { PropTypes } from 'prop-types';
import { OptionsAccordion } from '../../OptionsAccordion/OptionsAccordion';

import React from 'react';
import { Controller } from 'react-hook-form';
import { Copies, fields, getInventoryOptions } from './constants';
import { useInventory } from './hooks/useInventory';
import {
  AdditionalOptionsTypographyStyled,
  AdvancedSectionIconStyled,
  DividerStyled,
  DividerTypographyStyled,
  SectionBoxStyled,
  SectionInnerBoxStyled,
} from './styles';
import {
  faPen,
  faSquareArrowUpRight,
} from '@fortawesome/pro-regular-svg-icons';
import { DataList } from './DataList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { map } from 'lodash';
import {
  SectionInfo,
  sectionInfoType,
} from '../../../../components/SectionInfo';
import { useCopy } from '@hooks/copy';

const PenStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
  cursor: 'pointer',
  color: theme.palette.grey[5],
}));

const InventoryListView = ({ data, onAdvanced }) => (
  <Stack direction="row" alignItems="center" spacing={1}>
    <Typography variant="body2">{map(data, 'value').join(', ')}</Typography>
    <PenStyled
      role="button"
      onClick={() => {
        onAdvanced();
      }}
      icon={faPen}
    />
  </Stack>
);

InventoryListView.propTypes = {
  data: PropTypes.array,
  onAdvanced: PropTypes.func,
};

export const Inventory = ({
  bundles,
  onAdvanced,
  onAdvancedCustomInventory,
  onSelect,
  info,
  isDisplay,
  ...props
}) => {
  const Copy = useCopy(Copies);

  const {
    control,
    customInventoryItems,
    isCustomInventoryExpanded,
    setIsCustomInventoryExpanded,
    inventory: actualInventory,
    domain,
    selectRecommendedInventory,
  } = useInventory({ bundles });

  return (
    <SectionBoxStyled display="flex" flexDirection="column" {...props}>
      <DividerStyled textAlign="left">
        <DividerTypographyStyled>Inventory</DividerTypographyStyled>
      </DividerStyled>
      <SectionInnerBoxStyled>
        <SectionInfo infoBlocks={info} />
        <Controller
          control={control}
          name={fields.inventoryOption.path}
          render={({ field }) => (
            <OptionsAccordion
              {...field}
              trackingIsSetUp
              options={getInventoryOptions({
                onAdvancedCustomInventory,
                customInventoryItems,
                isCustomInventoryExpanded,
                setIsCustomInventoryExpanded,
                isDisplay,
                bundles,
                domain,
              })}
              headerTitle={Copy.inventoryLabel}
              onChange={(_, option) => {
                field.onChange(option.value);
                onSelect?.(option);
                if (
                  ['padman-premium-reach', 'performanceOptimized'].includes(
                    option.value
                  )
                ) {
                  selectRecommendedInventory();
                }
              }}
            />
          )}
        />
        <Stack spacing={1}>
          <AdditionalOptionsTypographyStyled
            onClick={onAdvanced}
            data-testid="advanced-inventory-options-button"
          >
            <AdvancedSectionIconStyled icon={faSquareArrowUpRight} />
            {Copy.advancedInventoryOptionsLabel}
          </AdditionalOptionsTypographyStyled>
          <DataList
            singleSubField
            title="Include"
            data={actualInventory.filter(v => v.included)}
            render={v => (
              <InventoryListView data={v} onAdvanced={onAdvanced} />
            )}
          />
          <DataList
            singleSubField
            title="Exclude"
            data={actualInventory.filter(v => !v.included)}
            render={v => (
              <InventoryListView data={v} onAdvanced={onAdvanced} />
            )}
          />
        </Stack>
      </SectionInnerBoxStyled>
    </SectionBoxStyled>
  );
};

Inventory.propTypes = {
  ...Stack.propTypes,
  bundles: PropTypes.arrayOf(PropTypes.object),
  onAdvanced: PropTypes.func,
  onAdvancedCustomInventory: PropTypes.func,
  onSelect: PropTypes.func,
  inventory: PropTypes.array,
  info: PropTypes.arrayOf(sectionInfoType),
  isDisplay: PropTypes.bool,
};
