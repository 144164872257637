import React from 'react';
import { type SectionInfoProps } from './types';
import { Stack } from '@mui/material';
import { Info } from '@v2/components/campaign/Info';

export const SectionInfo = ({ infoBlocks = [], sub }: SectionInfoProps) => {
  const actualBlocks = infoBlocks.filter(
    block => (sub ? block.sub === sub : true) && block.content
  );
  return actualBlocks.length ? (
    <Stack>
      {infoBlocks.map((infoBlock, index) => (
        <Info direction="column" key={index} type={infoBlock.type}>
          {infoBlock.content}
        </Info>
      ))}
    </Stack>
  ) : null;
};
