import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, styled, Typography } from '@mui/material';
import { Icon } from '../../Icon';

export const PenStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
  cursor: 'pointer',
  color: theme.palette.grey[5],
}));

export const WrapperBoxStyled = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.blue[11],
  borderRadius: '5px',
  padding: '14px 24px',
}));

export const IconStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.blue[6],
  cursor: 'pointer',
}));

export const PlainIconStyled = styled(Icon)(({ theme }) => ({
  color: theme.palette.blue[6],
  cursor: 'pointer',
}));

export const IconBoxStyled = styled(Stack)({
  width: '24px',
});

export const LabelStyled = styled(Typography)({
  fontWeight: 500,
});
