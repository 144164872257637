import { map } from 'lodash';
import { fields } from './Manage/constants';
import { Creative, Pixel } from '@local-types/creative';

const pixelField = fields.impression_tracking_pixel.path;

export const transformCreativeToImpressionPixelsArray = (
  creative: Creative
) => ({
  ...creative,
  [pixelField]: map(
    Array.isArray(creative[pixelField as 'pixels'])
      ? creative[pixelField as 'pixels']
      : JSON.parse(
          (creative[pixelField as 'pixels'] as string) ?? '[]'
        ).filter((tp: Pixel) => tp),
    pixel => ({
      value: pixel,
    })
  ),
});

export const transformFromImpressionPixelsArray = (
  creatives: Creative[] = []
) =>
  map(creatives, creative => ({
    ...creative,
    [pixelField]: JSON.stringify(
      map(creative[pixelField as 'pixels'], 'value')
        .map(v => v.trim())
        .filter(tp => tp)
    ),
  }));
