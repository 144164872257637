import { useMemo, useState, useEffect, forwardRef } from "react";
import { Paper, IconButton } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { ThemeProvider } from "@mui/material/styles";
import { defaultTheme_v2 } from "@themes/default_v2";
import NestedList from "@components/NestedList";
import { getInitialPath } from "./utils";
import { IFilterMenuProps, IMenuItem } from "./types";

const FilterMenu = forwardRef<HTMLDivElement, IFilterMenuProps>((props, ref) => {
  const {
    data,
    items,
    onClose,
    onSubmit,
  } = props;

  const [selectedPath, setSelectedPath] = useState<IMenuItem[]>([]);

  const { component: Form, id } = useMemo(
    () => selectedPath[selectedPath.length - 1] || {},
    [selectedPath]
  );

  const handleSelectItem = (path: IMenuItem[]) => {
    setSelectedPath(path);
  };

  useEffect(() => {
    if (selectedPath.length === 0 && items.length > 0) {
      setSelectedPath(getInitialPath(items));
    }
  }, [selectedPath, items]);


  return (
    <ThemeProvider theme={defaultTheme_v2}>
      <Paper
        component={Grid}
        container
        elevation={1}
        ref={ref}
        sx={{ bgcolor: 'common.offWhite', width: '100%', height: '100%', position: 'relative' }}
      >
        <IconButton
          onClick={onClose}
          size="small"
          sx={{
            position: 'absolute',
            right: 24,
            top: 16,
            color: 'grey.600',
            padding: '4px',
          }}
        >
          <FontAwesomeIcon icon={faXmark} style={{ fontSize: 16 }} />
        </IconButton>
        <Grid
          xs={3}
          sx={{
            bgcolor: 'grey.11',
            padding: theme => theme.spacing(2, .5),
            minHeight: '100%',
            minWidth: 200,
          }}
        >
          <NestedList
            items={items}
            selectedPath={selectedPath}
            onSelectItem={handleSelectItem}
          />
        </Grid>

        <Grid xs>
          {Form && (
            <Form
              key={id}
              data={data}
              onSubmit={onSubmit}
            />
          )}
        </Grid>
      </Paper>
    </ThemeProvider>
  );
});

FilterMenu.displayName = 'FilterMenu';

export default FilterMenu;
