import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { useNotification } from '@swr/notification';
import { logger } from '@swr/logging';
import { defaultTheme_v2 } from '@themes/default_v2';
import React, { Suspense, lazy } from 'react';
import AppHeader from '@components/AppHeader';
import { SWRConfig } from 'swr';
import { useWizardPage } from './hooks/useWizardPage';

const WizardPageContent = lazy(() => import('./WizardPageContent'));
const WizardQuickstartPageContent = lazy(
  () => import('./WizardQuickstartPageContent')
);

export const WizardPage = ({ quickstart }: { quickstart: boolean }) => {
  useWizardPage();
  return (
    <ThemeProvider theme={defaultTheme_v2}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <SWRConfig
          value={{
            use: [logger, useNotification],
            revalidateOnFocus: false,
          }}
        >
          <AppHeader>
            <Suspense fallback={'Loading...'}>
              {quickstart ? (
                <WizardQuickstartPageContent />
              ) : (
                <WizardPageContent />
              )}
            </Suspense>
          </AppHeader>
        </SWRConfig>
      </LocalizationProvider>
    </ThemeProvider>
  );
};
