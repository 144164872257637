import type { StackProps, Theme } from '@mui/material';
import PropTypes from 'prop-types';
import React, { HTMLAttributes } from 'react';

export const inventoryType = PropTypes.shape({
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.number,
  included: PropTypes.bool,
  featured: PropTypes.bool,
});

export const customInventoryType = PropTypes.shape({
  id: PropTypes.number,
  included: PropTypes.bool,
  featured: PropTypes.bool,
  adjusted_cpm: PropTypes.number,
  bundle_name: PropTypes.string,
  estimated_cpm: PropTypes.number,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  info: PropTypes.string,
  ott: PropTypes.bool,
  private: PropTypes.bool,
  type: PropTypes.string,
  url: PropTypes.string,
});

export type DataListProps<T> = (
  | {
      data: T[];
      title: string;
      render: (data: T[]) => React.ReactNode;
      singleSubField: true;
    }
  | {
      data: T[];
      title: string;
      singleSubField: undefined | false;
      render: (data: T, idx: number) => React.ReactNode;
    }
) &
  HTMLAttributes<HTMLUListElement>;

export type CellContentStyledProps = {
  featured?: boolean;
  color?: string;
  theme?: Theme;
} & StackProps;
