import React, { HTMLAttributes } from 'react';

import { useTheme } from '@mui/material/styles';
import { TvSciQSvgIcon } from '../icons/TvSciQSvgIcon';
import {
  BoldTextStyled,
  IconTextContainerStyled,
  TypographyStyled,
} from './styles';

export const TvSciQIconWithText = (props: HTMLAttributes<HTMLDivElement>) => {
  const theme = useTheme();
  return (
    <IconTextContainerStyled {...props}>
      <TvSciQSvgIcon fill={theme.palette.green[8]} />
      {/** @ts-expect-error wrong typing exposure from styled wrapper. */}
      <TypographyStyled component="span">
        tvSc<BoldTextStyled>iQ</BoldTextStyled>
      </TypographyStyled>
    </IconTextContainerStyled>
  );
};
