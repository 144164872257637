import { useMemo } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';
import { useCurrentSession } from '../currentSession';
import { formatFileName } from '../upload';
import { useLoadInfinite } from './general';
import axios from 'axios';
import useSWRInfinite from 'swr/infinite';

const buildCreativesCacheKey = (currentAdvertiser, params = {}) => {
  const url = '/creatives/';

  const cacheKey = {
    url,
    advertiser: currentAdvertiser.id ?? '-',
    ...params,
  };

  return cacheKey;
};

const buildCreativeCacheKey = (
  creativeId,
  currentAdvertiser,
  params = {},
) => {
  const url = `/creatives/${creativeId}`;

  const cacheKey = {
    url,
    advertiser: currentAdvertiser.id ?? '-',
    ...params,
  };

  return cacheKey;
};

export const useCreatives = ({ params = {}, options = {} }) => {
  // getting currentAdvertiser to include its id on the cache key
  const { getV1, apiIsReady, currentAdvertiser, post } = useCurrentSession();
  const fetcher = ({ url, params }) =>
    getV1(url, params).then(res => res.data);

  const { data, mutate, ...rest } = useSWRInfinite(
    (index, previousPageData) => {
      if (!apiIsReady || (previousPageData && !previousPageData.next))
        return null;

      return {
        url: '/creatives/',
        advertiser: currentAdvertiser.id ?? '-',
        params: {
          sync: 1,
          page_size: 25,
          ...(index && { page: index + 1 }),
          ...params,
        },
      };
    },
    fetcher,
    {
      revalidateFirstPage: false,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      ...options,
    },
  );

  const archiveUnarchiveCreative = async (
    { url },
    { arg: { id, isArchiving } },
  ) => {
    const endpoint = isArchiving ? '/archive/' : '/unarchive/';
    return post(`${url}${id}${endpoint}`).then(res => res.data);
  };

  const { trigger: archiveTrigger } = useSWRMutation(
    apiIsReady && currentAdvertiser.id ? { url: `/creatives/` } : null,
    archiveUnarchiveCreative,
    {
      onSuccess: () => {
        mutate();
      },
      revalidate: true,
    },
  );

  const items = useMemo(
    () => (data ? [].concat(...data.map(page => page?.results ?? [])) : []),
    [data],
  );
  const totalItems = data?.[0]?.count;

  return {
    creatives: items,
    nextPage: data?.next,
    previousPage: data?.previous,
    count: totalItems,
    archiveTrigger,
    hasMore:
      totalItems === undefined ||
      (totalItems > 0 && items?.length < totalItems),
    ...rest,
  };
};

export const usePatchCreative = () => {
  const { patch, apiIsReady, currentAdvertiser } = useCurrentSession();
  const { mutate } = useSWRConfig();

  const updateCreative = (url, { arg: { id, ...data } }) =>
    patch(`${url}${id}/`, data).then(res => res.data);

  const options = {
    onSuccess: updatedCreative => {
      const key = buildCreativeCacheKey(
        updatedCreative.id,
        currentAdvertiser,
      );
      mutate(key, updatedCreative, { populateCache: true });
    },
    revalidate: false,
  };

  const { trigger, isMutating } = useSWRMutation(
    apiIsReady ? `/creatives/` : null,
    updateCreative,
    options,
  );

  return { trigger, isMutating };
};

export const usePatchCreativeLineItem = () => {
  const { patch } = useCurrentSession();

  const updateCreative = (url, { arg: { id, ...data } }) =>
    patch(`${url}${id}/`, data).then(res => res.data);

  const options = {};

  const { trigger, isMutating } = useSWRMutation(
    `/creativelineitems/`,
    updateCreative,
    options,
  );

  return { trigger, isMutating };
};

export const useGetCreativeLineItemTrigger = () => {
  const { get, apiIsReady } = useCurrentSession();

  const { trigger } = useSWRMutation(
    apiIsReady ? `/creativelineitems/` : null,
    (url, { arg: id }) => {
      return get(`${url}?line_item_id=${id}`).then(
        res => res.data.results ?? [],
      );
    },
  );

  return { trigger };
};

export const useGetCreativeLineItems = (adGroupId, includeCreatives) => {
  const { get, apiIsReady } = useCurrentSession();

  const { data = [], isLoading, isValidating } = useSWR(
    apiIsReady && adGroupId ? `/creativelineitems/` : null,
    url => {
      return get(`${url}?line_item_id=${adGroupId}`).then(res => {
        if (!includeCreatives) {
          return res.data.results;
        }
        return Promise.all(
          res.data.results.map(async lineItem => {
            return {
              ...lineItem,
              creative_object: (await get(lineItem.creative_url))?.data,
            };
          }),
        );
      });
    },
  );

  return {
    data,
    isLoading: isLoading || isValidating,
  };
};

export const useCreativeVideoUploadV1 = () => {
  // TODO: delete this once flags.LARGE_ASSET_UPLOAD_FEATURE is removed

  const { postV1, apiIsReady } = useCurrentSession();

  const createCreative = (url, { arg: { data, ...rest } }) => {
    return postV1(`${url}`, data, {
      ...rest,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(res => res.data);
  };

  const options = {};

  const { trigger, isMutating } = useSWRMutation(
    apiIsReady ? '/video_assets/' : null,
    createCreative,
    options,
  );

  return {
    trigger: ({ file, advertiser, listener }) => {
      const abortController = new AbortController();
      const formData = new FormData();
      formData.append('file', file);
      formData.append('name', formatFileName(file.name));
      formData.append('advertiser', advertiser);
      formData.append('media_type', file.type);

      const result = trigger({
        data: formData,
        onUploadProgress: listener,
        signal: abortController.signal,
      });

      return {
        result,
        cancel: () => abortController.abort(),
      };
    },
    isMutating,
  };
};

export const useCreativeVideoUpload = () => {
  const { post, apiIsReady } = useCurrentSession();

  const createCreative = (url, { arg: { data, ...rest } }) => {
    return post(`${url}`, data, {
      ...rest,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(res => res.data);
  };

  const options = {};

  const { trigger, isMutating } = useSWRMutation(
    apiIsReady ? '/videoassets/' : null,
    createCreative,
    options,
  );

  return {
    trigger: ({ file, advertiser, s3_key }) => {
      const formData = new FormData();
      formData.append('name', formatFileName(file.name));
      formData.append('advertiser', advertiser);
      formData.append('media_type', file.type);
      formData.append('s3_key', s3_key);

      const result = trigger({ data: formData });

      return { result };
    },
    isMutating,
  };
};

export const usePostCreative = () => {
  const { post, apiIsReady } = useCurrentSession();

  const createCreative = (url, { arg }) =>
    post(`${url}`, arg).then(res => res.data);

  const options = {};

  const { trigger, isMutating } = useSWRMutation(
    apiIsReady ? `/creatives/` : null,
    createCreative,
    options,
  );

  return { trigger, isMutating };
};

export const useGetUploadUrl = () => {
  const { post, apiIsReady } = useCurrentSession();

  const createS3UploadUrl = (url, { arg }) =>
    post(`${url}`, arg).then(res => res.data);

  const { trigger, isMutating } = useSWRMutation(
    apiIsReady ? '/assets/upload_url/' : null,
    createS3UploadUrl,
  );

  return { trigger, isMutating };
};

export const uploadToS3Bucket = async ({
  url,
  file,
  onUploadProgress,
  abortSignal,
}) => {
  await axios.put(url, file, {
    headers: {
      'Content-Type': '', // remove content type to avoid signature mismatch
    },
    onUploadProgress,
    signal: abortSignal,
  });
};

export const useGetCreatives = (options = {}) => {
  const { get, apiIsReady, currentAdvertiser } = useCurrentSession();
  const { disabled = false, ...otherOptions } = options;

  const fetcher = ({ url, params }) =>
    get(url, { params }).then(res => res.data);

  const params = {
    expand: 'lineitem_set',
  };

  const { data, mutate, isLoading } = useSWR(
    apiIsReady && !disabled
      ? buildCreativesCacheKey(currentAdvertiser, params)
      : null,
    fetcher,
    otherOptions,
  );

  const items = useMemo(() => data?.results ?? [], [data]);

  return {
    data,
    items,
    mutate: d => mutate(d, otherOptions),
    invalidate: mutate,
    isLoading,
  };
};

export const useGetAllCreatives = (options = {}) => {
  const { mutate, items, isLoading } = useLoadInfinite('creatives/', {
    params: {
      ...options,
    },
  });

  return { items, invalidate: mutate, isLoading };
};

export const useAdGroupCreatives = (adGroup, options = {}) => {
  const { get, apiIsReady } = useCurrentSession();
  const fetcher = urls => {
    return Promise.all(urls.map(url => get(url).then(res => res.data)));
  };

  const urls =
    adGroup?.creatives?.map(creativeId => ({
      url: `/creatives/${creativeId}/`,
      adGroupId: adGroup.id,
    })) ?? [];

  const swr = useSWR(
    !options.disabled && apiIsReady && urls.length > 0
      ? urls.map(item => item.url)
      : null,
    fetcher,
  );

  const { data, error, isLoading } = swr;

  return {
    adGroupsCreatives: data,
    items: data,
    error,
    isLoading,
  };
};
