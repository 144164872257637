import {
  Avatar,
  Button,
  Divider,
  Link,
  Stack,
  Typography,
  styled,
  ListItem,
} from '@mui/material';
import { Icon } from '../Icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VideoPreview } from './AdvancedSubflows/AdvancedCreative/VideoPreview';
import type { CellContentStyledProps } from './types';
import { defaultTo, get } from 'lodash';

export const AvatarImageStyled = styled('img')({
  width: '100%',
  height: 'auto',
  objectFit: 'fill',
  scale: 0.75,
});

export const AdditionalOptionsTypographyStyled = styled(Typography)(
  ({ theme }) => ({
    display: 'inline-flex',
    flexDirection: 'row',
    gap: theme.spacing(1.25),
    alignItems: 'center',
    cursor: 'pointer',
    fontWeight: 700,
  })
);

export const DividerStyled = styled(Divider)(({ theme }) => ({
  '&.MuiDivider-root': {
    '&:before': {
      width: theme.spacing(2.25),
    },
  },
}));

export const DividerTypographyStyled = styled(Typography)({
  fontWeight: 700,
});

export const SectionBoxStyled = styled(Stack)(({ theme }) => ({
  paddingBottom: theme.spacing(5),
}));

export const SectionInnerBoxStyled = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(3, 2.25, 0),
  gap: theme.spacing(4),
}));

export const CancelButtonStyled = styled(Button)(({ theme }) => ({
  color: theme.palette.blue[6],
  flex: 1,
}));

export const LinkStyled = styled(Link)(() => ({
  fontWeight: 700,
  fontSize: '0.875rem',
  gap: 4,
  display: 'inline-flex',
  alignItems: 'center',
  cursor: 'pointer',
}));

export const ReactiveIconSmall = styled(Icon)(({ theme }) => ({
  width: theme.spacing(1.5),
  height: theme.spacing(1.5),
}));

export const AdvancedSectionIconStyled = styled(FontAwesomeIcon)(
  ({ theme }) => ({
    color: theme.palette.blue[6],
    cursor: 'pointer',
    width: theme.spacing(2.4375),
    height: theme.spacing(2.4375),
  })
);

export const VideoPreviewStyled = styled(VideoPreview)(({ theme }) => ({
  width: theme.spacing(15),
  height: theme.spacing(8.375),
  borderRadius: 0,
}));

export const ImagePreviewStyled = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(15),
  height: theme.spacing(8.375),
  borderRadius: 0,
}));

export const GeneralItemTypographyStyled = styled(Typography)(
  ({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
  })
);

export const GeneralListStyled = styled('ul')(() => ({
  listStyleType: 'disc',
  margin: 0,
}));

export const GeneralListItemStyled = styled('li')(() => ({
  padding: 0,
}));

export const SubListItemStyled = styled(ListItem)(({ theme }) => ({
  alignItems: 'flex-start',
  boxAlign: 'start',
  padding: theme.spacing(0, 1),
}));

export const TableCellAdditionalDataBlockStyled = styled(Typography)(
  ({ theme }) => ({
    color: theme.palette.grey[3],
    fontSize: theme.spacing(1.25),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
  })
);

export const CellContentStyled = styled(Stack, {
  shouldForwardProp: prop => prop !== 'color' && prop !== 'featured',
})(({ theme, color, featured }: CellContentStyledProps) => ({
  '&::before': {
    content: '""',
    width: 4,
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    backgroundColor: featured
      ? get(theme?.palette, defaultTo(color, 'green.9'))
      : 'transparent',
  },
}));
