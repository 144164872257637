import { Fields, FormFieldsStructure } from '@local-types';
import { object } from 'yup';
import { ObjectShape } from 'yup/lib/object';

export const getFormDefaultValues = <T extends FormFieldsStructure>(
  fields: T
): Fields<T> => {
  const defaultValues = Object.entries(fields).reduce((acc, [_, field]) => {
    // @ts-expect-error acc key missing
    acc[field.path] = field.defaultValue ?? null;
    return acc;
  }, {} as Fields<T>);

  return defaultValues;
};

export const generateSchemaFromFields = <T extends FormFieldsStructure>(
  fields: T
) => {
  const rules = Object.entries(fields).reduce((acc, [_, field]) => {
    if (field.rule) {
      acc[field.path] = field.rule;
    }
    return acc;
  }, {} as ObjectShape);

  return object().shape(rules);
};
