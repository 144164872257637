import React from 'react';
import PropTypes from 'prop-types';

import { useLibrary } from './hooks/useLibrary';
import { AdvancedBox } from '@v2/components/campaign/CampaignAdGroupSection/AdvancedBox/AdvancedBox';
import { AdvancedBoxFooter } from '@v2/components/campaign/CampaignAdGroupSection/AdvancedBox/AdvancedBoxFooter';
import {
  Button,
  InputAdornment,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { LinkStyled } from '../../../styles';
import { Controller } from 'react-hook-form';
import { fields, TABS } from '../constants';
import { Edit } from '../Upload/Edit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { TableRowGeneral } from './TableRowGeneral';
import clsx from 'clsx';
import { tableOptions } from './constants';
import {
  CreativeWrapperStyled,
  SearchFieldStyled,
  StyledClearIcon,
  StyledSearchIcon,
  TableStyled,
} from './styles';

export const Library = ({ onClose, onSubmit, isDisplay, info, ...props }) => {
  const {
    library,
    columns,
    setTab,
    sort,
    drop,
    order,
    creatives,
    cancel,
    showDropzone,
    orderBy,
    control,
    search,
    setValue,
  } = useLibrary({ onClose });

  return (
    <AdvancedBox
      {...props}
      info={info.info}
      onClose={onClose}
      header={
        <Stack direction="row" alignItems="center">
          <Stack
            sx={{
              minWidth: theme => theme.spacing(32.75),
              maxWidth: theme => theme.spacing(32.75),
            }}
          >
            <Typography
              data-testid="creatives-list-or-upload-screen-header"
              variant="h3"
            >
              Step 1: {info.select}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            spacing={4}
            sx={{ flexGrow: 1, justifyContent: 'flex-end' }}
          >
            {!showDropzone && (
              <Controller
                control={control}
                name={fields.search.path}
                render={({ field }) => (
                  <SearchFieldStyled
                    {...field}
                    placeholder={
                      isDisplay ? 'Search displays' : 'Search videos'
                    }
                    variant="outlined"
                    inputProps={{
                      'data-testid': 'creatives-search-input',
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {search ? (
                            <StyledClearIcon
                              icon={faTimes}
                              onClick={() => {
                                setValue(fields.search.path, '');
                              }}
                            />
                          ) : (
                            <StyledSearchIcon />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            )}
            <LinkStyled
              underline="hover"
              onClick={() =>
                setTab({
                  key: TABS.UPLOAD_ASSET,
                })
              }
              sx={{ minWidth: theme => theme.spacing(16.875) }}
              data-testid="upload-video-button"
            >
              <FontAwesomeIcon icon={faPlusCircle} />
              {info.upload}
            </LinkStyled>
            {!isDisplay && (
              <LinkStyled
                underline="hover"
                onClick={() =>
                  setTab({
                    key: TABS.VAST_TAG,
                  })
                }
                sx={{ minWidth: theme => theme.spacing(16.875) }}
              >
                <FontAwesomeIcon icon={faPlusCircle} /> Add by VAST Tag
              </LinkStyled>
            )}
          </Stack>
        </Stack>
      }
    >
      <CreativeWrapperStyled flex={1} spacing={5}>
        <Stack flex={1} spacing={4.25}>
          <TableStyled>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    width: 0,
                  }}
                />
                {columns.map(column => (
                  <TableCell
                    className={clsx({
                      sticky:
                        tableOptions.sticky.left.includes(column.id) ||
                        tableOptions.sticky.right.includes(column.id),
                      'sticky-left': tableOptions.sticky.left.includes(
                        column.id
                      ),
                      'sticky-right': tableOptions.sticky.right.includes(
                        column.id
                      ),
                    })}
                    sx={column.headerSx}
                    key={column.id}
                  >
                    {column.sortable ? (
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : 'asc'}
                        onClick={e => {
                          sort(e, column);
                        }}
                      >
                        {column.label}
                      </TableSortLabel>
                    ) : (
                      column.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {library.map(row => (
                <TableRowGeneral
                  key={row.id}
                  item={row}
                  columns={columns}
                  search={search} // Use the search value from useLibrary
                />
              ))}
            </TableBody>
          </TableStyled>
          {!showDropzone && search && !library?.length && (
            <Stack flex={1} alignItems="center" justifyContent="center">
              <Typography
                data-testid="creative-search-no-match-message"
                variant="body2"
              >
                Your search - <b>{search}</b> - did not match any results
              </Typography>
            </Stack>
          )}
          {showDropzone && (
            <Edit flex={1} isDisplay={isDisplay} onDrop={drop}>
              <Typography
                data-testid="no-creatives-available-message"
                variant="body2"
              >
                No creatives in library
              </Typography>
            </Edit>
          )}
        </Stack>
      </CreativeWrapperStyled>
      <AdvancedBoxFooter>
        <Button onClick={cancel} color="secondary" variant="text">
          Cancel
        </Button>
        <Button
          disabled={!creatives?.length}
          onClick={onSubmit}
          variant="contained"
          data-testid="next-manage-creative"
        >
          Next: Manage Creative
        </Button>
      </AdvancedBoxFooter>
    </AdvancedBox>
  );
};

Library.propTypes = {
  ...AdvancedBox.propTypes,
  isDisplay: PropTypes.bool,
  info: PropTypes.object,
};
