import { Box, Tab } from '@mui/material';
import { PropTypes } from 'prop-types';
import React from 'react';
import { TabTypographyStyled, TabsStyled } from './styles';
import { tabType } from './types';
import { Hint } from '../../ui/Hint';

export const Tabs = ({ data, selected, onChange, ...props }) => {
  return (
    <Box {...props}>
      <TabsStyled value={selected} onChange={(_, tab) => onChange(tab)}>
        {data.map(tab => (
          <Tab
            value={tab.value}
            data-testid={`${tab.value.toLowerCase().replace(' ', '-')}-tab`}
            label={
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={1}
              >
                <TabTypographyStyled
                  warning={tab.settings?.warning}
                  selected={selected === tab.value}
                >
                  {tab.label}
                </TabTypographyStyled>
                {tab.hint && <Hint>{tab.hint}</Hint>}
              </Box>
            }
            key={tab.value}
          />
        ))}
      </TabsStyled>
    </Box>
  );
};

Tabs.propTypes = {
  data: PropTypes.arrayOf(tabType).isRequired,
  selected: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  ...Box.propTypes,
};
