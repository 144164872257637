import { Typography } from '@mui/material';
import { InfoBoxStyled } from './styles';
import { InfoBoxProps } from './types';

export const InfoBox = ({
  text,
  isLocked,
  isRecommended,
  trackingIsSetUp,
  ...props
}: InfoBoxProps) => {
  if (isRecommended && !isLocked) {
    return (
      <InfoBoxStyled {...props} trackingIsSetUp={trackingIsSetUp}>
        <Typography component="span" variant="body2">
          {text}
        </Typography>
      </InfoBoxStyled>
    );
  }
  return null;
};
