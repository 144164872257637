const PAUSED = 1;
const PENDING = 2;
const ACTIVE = 3;
const DRAFT = 4;
const ARCHIVED = 5;
const TEMPORARY = 0;

export const Statuses = {
  PAUSED,
  PENDING,
  ACTIVE,
  DRAFT,
  ARCHIVED,
  TEMPORARY,
};

export const DefaultStatusKeys = [PAUSED, PENDING, ACTIVE, DRAFT];

export const StatusKeys = [ACTIVE, PENDING, PAUSED, DRAFT, ARCHIVED];

export const StatusNames = {
  [ACTIVE]: 'Active',
  [PENDING]: 'Pending',
  [PAUSED]: 'Paused',
  [DRAFT]: 'Draft',
  [ARCHIVED]: 'Archived',
};

export const StatusList = [
  {
    name: StatusNames[Statuses.ACTIVE],
    value: Statuses.ACTIVE,
  },
  {
    name: StatusNames[Statuses.PENDING],
    value: Statuses.PENDING,
  },
  {
    name: StatusNames[Statuses.PAUSED],
    value: Statuses.PAUSED,
  },
  {
    name: StatusNames[Statuses.DRAFT],
    value: Statuses.DRAFT,
  },
  {
    name: StatusNames[Statuses.ARCHIVED],
    value: Statuses.ARCHIVED,
  },
];
