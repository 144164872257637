import React from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { generatePath } from 'react-router-dom';
import { CreativeAdGroupV1, LineItem } from '@local-types';
import { RoutePaths } from '@constants/routes';
import { DataGridLink } from '@v2/components/ui/DataGrid/DataGridLink';

export const CreativeNameCellRenderer = (
  params: GridCellParams<CreativeAdGroupV1['creative_object']>,
) => {
  const { lineitem_set, id: creativeId, name } = params.row;

  const campaign = (lineitem_set?.[0] as LineItem)?.campaign ?? 0;
  const campaignId =
    typeof campaign === 'string'
      ? campaign?.split('/campaigns/')[1]?.slice(0, -1)
      : (campaign as number);

  const adGroupId =
    (lineitem_set?.[0] as LineItem)?.url
      ?.split('/lineitems/')[1]
      ?.slice(0, -1) ?? 'na';

  if (campaignId === 'na' || adGroupId === 'na' || !creativeId)
    return <span>{name}</span>;

  return (
    <DataGridLink
      to={generatePath(RoutePaths.CREATIVE_DETAIL, {
        campaignId,
        adGroupId,
        creativeId,
      })}
    >
      {name}
    </DataGridLink>
  );
};
