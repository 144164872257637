import React, { useMemo } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { DataGrid } from '@v2/components/ui/DataGrid/DataGrid';
import { defaultTheme_v2 } from '@themes/default_v2';
import { DateCellRenderer } from '@v2/components/ui/DataGrid/renderers/DateCellRenderer';
import { DotNotationValueGetter } from '@v2/components/ui/DataGrid/DotNotationValueGetter';
import { CurrencyCellRenderer } from '@v2/components/ui/DataGrid/renderers/CurrencyCellRenderer';
import { CreativeNameCellRenderer } from './renderers/CreativeNameCellRenderer';
import { CreativeStatusCellRenderer } from '@components/containers/CreativeIndexPage/renderers/CreativeStatusCellRenderer';
import { Scopes } from '@constants/scopes';
import { usePermissions } from '@components/hooks/permissions';
import { CreativeQCAlerts } from '@components/CreativeQCAlerts';
import { CreativeAdGroupCellRenderer } from './renderers/CreativeAdGroupCellRenderer';
import { GridColDef } from '@mui/x-data-grid-pro';
import { CreativeActionMenuCellRenderer } from '@components/Creatives/renderers/CreativeActionMenuCellRender';
import {
  CreativesTableContext,
  CreativesTableContextProvider,
} from '@components/Creatives/CreativesTableContext';
import { Box } from '@mui/material';

export const CreativesTableV2 = () => {
  const { hasPermission } = usePermissions();
  const showQCFunctionality = hasPermission([Scopes.CAN_VIEW_QC]);

  const columns = useMemo(
    () =>
      [
        {
          field: 'id',
          headerName: 'ID',
          width: 60,
          resizable: false,
          hideable: false,
        },
        {
          field: 'name',
          headerName: 'Name',
          renderCell: CreativeNameCellRenderer,
          minWidth: 220,
          hideable: false,
          sortable: true,
        },
        {
          field: 'active',
          headerName: 'Status',
          renderCell: CreativeStatusCellRenderer,
          minWidth: 180,
          hideable: false,
          sortable: false,
        },
        {
          field: 'action',
          headerName: '',
          width: 50,
          renderCell: CreativeActionMenuCellRenderer,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
        },
        {
          field: 'adGroup',
          headerName: 'Ad Group',
          flex: 1,
          renderCell: CreativeAdGroupCellRenderer,
          resizable: false,
          hideable: false,
          sortable: false,
        },
        {
          field: 'media_upload_date',
          headerName: 'Uploaded',
          renderCell: DateCellRenderer,
          minWidth: 150,
          resizable: false,
          hideable: false,
          sortable: false,
        },
        {
          field: 'stats.total_spend',
          headerName: 'Spend',
          valueGetter: DotNotationValueGetter,
          renderCell: CurrencyCellRenderer,
          minWidth: 120,
          align: 'right',
          resizable: false,
          hideable: false,
          sortable: false,
        },
        {
          field: 'stats.visit_count',
          headerName: 'Visits',
          valueGetter: DotNotationValueGetter,
          minWidth: 120,
          align: 'right',
          resizable: false,
          hideable: false,
          sortable: false,
        },
        {
          field: 'stats.cost_per_visit',
          headerName: 'Cost Per Visit',
          valueGetter: DotNotationValueGetter,
          renderCell: CurrencyCellRenderer,
          minWidth: 120,
          align: 'right',
          resizable: false,
          hideable: false,
          sortable: false,
        },
      ].filter(Boolean) as GridColDef[],
    [],
  );

  return (
    <ThemeProvider theme={defaultTheme_v2}>
      <CreativesTableContextProvider>
        <CreativesTableContext.Consumer>
          {({
            count,
            creatives,
            isLoading,
            setSize,
            hasMore,
            isValidating,
          }) => {
            return (
              <>
                {showQCFunctionality && (
                  <CreativeQCAlerts
                    email={'support@tvscientific.com'}
                    items={creatives || []}
                  />
                )}
                <Box sx={{ height: '70vh' }}>
                  <DataGrid
                    onRowsScrollEnd={() => {
                      if (hasMore && !isLoading) {
                        setSize(size => size + 1);
                      }
                    }}
                    initialState={{
                      pinnedColumns: {
                        left: ['id', 'name', 'active', 'action'],
                      },
                    }}
                    scrollEndThreshold={200}
                    loading={isLoading || isValidating}
                    rows={creatives}
                    rowCount={count}
                    columns={columns}
                    disableRowSelectionOnClick
                  />
                </Box>
              </>
            );
          }}
        </CreativesTableContext.Consumer>
      </CreativesTableContextProvider>
    </ThemeProvider>
  );
};
