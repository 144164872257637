import React from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { Creative } from '@local-types';
import { useAdGroupCreativeTable } from '../AdGroupCreativeTableContext';
import { DataGridLink } from '@v2/components/ui/DataGrid/DataGridLink';

export const CreativeNameCellRenderer = (params: GridCellParams<Creative>) => {
  const { adGroupId, campaignId } = useAdGroupCreativeTable();
  const { name, id } = params.row;

  return (
    <DataGridLink
      to={`/campaigns/${campaignId}/adgroups/${adGroupId}/creative/${id}`}
    >
      {name}
    </DataGridLink>
  );
};
