import React from 'react';
import { type Control, type FieldArrayWithId } from 'react-hook-form';
import { Row } from '../Row';
import { IFormValues } from '../types/ListFilterFormProps.types';

interface VirtualizedRowProps {
  index: number;
  style: React.CSSProperties;
  data: {
    items: FieldArrayWithId<IFormValues, 'items', 'internalId'>[];
    control: Control<IFormValues>;
    fields: FieldArrayWithId<IFormValues, 'items', 'internalId'>[];
    handleCheckboxChange: (checked: boolean, itemId: number) => void;
  };
}

const VirtualizedRow = React.memo(({ index, style, data }: VirtualizedRowProps) => {
  const item = data.items[index];
  if (!item) return null;

  return (
    <Row
      item={item}
      control={data.control}
      fields={data.fields}
      handleCheckboxChange={data.handleCheckboxChange}
      style={style}
    />
  );
});

VirtualizedRow.displayName = 'VirtualizedRow';

export { VirtualizedRow };
