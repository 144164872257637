export const allValue = 'All';

export const genders = [
  {
    value: allValue,
    label: 'All Genders',
  },
  {
    value: 'Male',
    label: 'Male',
  },
  {
    value: 'Female',
    label: 'Female',
  },
];

export const ages = [
  {
    value: [],
    label: 'All Ages',
    activable: false,
  },
  {
    value: [18, 25],
    label: '18-25',
  },
  {
    value: [25, 35],
    label: '25-35',
  },
  {
    value: [35, 45],
    label: '35-45',
  },
  {
    value: [45, 55],
    label: '45-55',
  },
  {
    value: [55, 65],
    label: '55-65',
  },
  {
    value: [65, 66],
    label: '65+',
  },
];

export const incomes = [
  {
    value: [],
    label: 'All Incomes',
    activable: false,
  },
  {
    value: [29, 30],
    label: '< $30K',
  },
  {
    value: [30, 40],
    label: '$30K - 40K',
  },
  {
    value: [40, 50],
    label: '$40K - 50K',
  },
  {
    value: [50, 60],
    label: '$50K - 60K',
  },
  {
    value: [60, 75],
    label: '$60K - 75K',
  },
  {
    value: [75, 100],
    label: '$75K - 100K',
  },
  {
    value: [100, 125],
    label: '$100K - 125K',
  },
  {
    value: [125, 150],
    label: '$125K - 150K',
  },
  {
    value: [150, 200],
    label: '$150K - 200K',
  },
  {
    value: [200, 250],
    label: '$200K - 250K',
  },
  {
    value: [250, 251],
    label: '> $250K',
  },
];

export const blockTypeData = {
  gender: {
    icon: 'gender',
  },
  age: {
    icon: 'age',
  },
  income: {
    icon: 'hhi',
  },
} as const;
