import React from 'react';
import { InfoBoxStyled } from './styles';
import { Typography } from '@mui/material';
import type { TextRenderBoxProps } from './types';

export const ProTipBox = ({ text, ...props }: TextRenderBoxProps) => {
  return (
    <InfoBoxStyled {...props}>
      <Typography component="span" variant="body2">
        {text}
      </Typography>
    </InfoBoxStyled>
  );
};
