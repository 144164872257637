import React from 'react';
import { List, Stack } from '@mui/material';
import {
  GeneralItemTypographyStyled,
  GeneralListItemStyled,
  GeneralListStyled,
  SubListItemStyled,
} from './styles';
import { DataListProps } from './types';

export const DataList = <T,>({
  data,
  title,
  render,
  singleSubField = false,
  ...props
}: DataListProps<T>) => {
  return (
    <GeneralListStyled {...props} disablePadding>
      {!!data.length && (
        <GeneralListItemStyled>
          <Stack direction="row">
            <GeneralItemTypographyStyled variant="body2">
              {title}
            </GeneralItemTypographyStyled>
            <List disablePadding>
              {singleSubField ? (
                <SubListItemStyled>
                  {(render as (data: T[]) => React.ReactNode)(data)}
                </SubListItemStyled>
              ) : (
                data.map((c: T, idx: number) => (
                  <SubListItemStyled key={idx}>
                    {(render as (data: T, idx: number) => React.ReactNode)(
                      c,
                      idx
                    )}
                  </SubListItemStyled>
                ))
              )}
            </List>
          </Stack>
        </GeneralListItemStyled>
      )}
    </GeneralListStyled>
  );
};
