import React from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { CreativeAdGroupV1 } from '@local-types';
import { Button } from '@mui/material';
import { useCreativeLibrary } from '../CreativeLibraryContext';

export const CreativeLibraryAssignCellRenderer = (
  params: GridCellParams<CreativeAdGroupV1['creative_object']>,
) => {
  const creative = params.row;
  const { assigned, handleToggleAssign } = useCreativeLibrary();
  const isAssigned = assigned.findIndex(a => a.url === creative.url) > -1;

  return (
    <Button
      disableRipple
      onClick={() => handleToggleAssign(creative)}
      variant="text"
      color="primary"
      size="small"
      sx={{
        color: isAssigned ? 'error.main' : 'primary.main',
        fontSize: '0.85rem',
      }}
    >
      {isAssigned ? 'Unassign' : 'Assign'}
    </Button>
  );
};
