import React from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { CreativeAdGroupV1 } from '@local-types';
import { Box, IconButton, Tooltip } from '@mui/material';
import PlayCircleOutline from '@mui/icons-material/PlayCircleOutline';
import { useCreativeLibrary } from '../CreativeLibraryContext';
import MiddleEllipsis from '@helpers/ellipsis';

export const CreativeLibraryTitleCellRenderer = (
  params: GridCellParams<CreativeAdGroupV1['creative_object']>,
) => {
  const { handlePreview } = useCreativeLibrary();

  const creative = params.row;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
      <IconButton onClick={() => handlePreview(creative)} size="small">
        <PlayCircleOutline />
      </IconButton>
      <Tooltip title={creative.name}>
        <MiddleEllipsis>
          <span>{creative.name}</span>
        </MiddleEllipsis>
      </Tooltip>
    </Box>
  );
};
