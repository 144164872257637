import React from 'react';
import { TypographyElementProps } from './types';
import { Text } from '../campaign/Text';

export const TypographyElement = ({
  children,
  ...props
}: TypographyElementProps) => {
  return React.isValidElement(children) ? (
    children
  ) : (
    <Text data-testid="options-accordiong-typography-element" {...props}>
      {children}
    </Text>
  );
};
