import React from 'react';
import { useCampaignsTable } from './CampaignsTableContext';
import { DataGrid } from '@v2/components/ui/DataGrid/DataGrid';
import { DataGridColumn } from '@v2/components/ui/DataGrid/types';
import { CampaignActionMenuCellRenderer } from '@components/containers/CampaignsPage/renderers/CampaignActionMenuCellRenderer';
import { CampaignStatusCellRenderer } from '@components/containers/CampaignsPage/renderers/CampaignStatusCellRenderer';
import { CampaignNameCellRenderer } from '@components/containers/CampaignsPage/renderers/CampaignNameCellRenderer';
import { DateCellRenderer } from '@v2/components/ui/DataGrid/renderers/DateCellRenderer';
import { CurrencyCellRenderer } from '@v2/components/ui/DataGrid/renderers/CurrencyCellRenderer';
import { DotNotationValueGetter } from '@v2/components/ui/DataGrid/DotNotationValueGetter';
import { Stack } from '@mui/material';
import { DataGridFilter } from '@v2/components/ui/DataGrid/DataGridFilter';

const CampaignsTableV2 = () => {
  const {
    rowCount,
    campaigns,
    isLoading,
    isValidating,
    sortModel,
    setSortModel,
    onFilterChange,
    filter,
    hasMore,
    setSize,
  } = useCampaignsTable();

  return (
    <Stack sx={{ height: '70vh' }}>
      <DataGridFilter
        filter={filter}
        onFilterChange={onFilterChange}
        placeholder="Search campaigns by name or ID..."
      />
      <DataGrid
        onRowsScrollEnd={() => {
          if (hasMore && !isLoading) {
            setSize(size => size + 1);
          }
        }}
        scrollEndThreshold={200}
        hasHeader
        sortingMode="server"
        paginationMode="server"
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        loading={isLoading || isValidating}
        rows={campaigns}
        rowCount={rowCount ?? 0}
        disableRowSelectionOnClick
        getRowId={(row: { id: string }) => row.id}
        slotProps={{
          row: {
            'data-testid': 'campaign-row',
          },
        }}
        initialState={{
          pinnedColumns: { left: ['id', 'name', 'status', 'action'] },
        }}
        columns={
          [
            {
              field: 'id',
              headerName: 'ID',
              width: 100,
              resizable: false,
              hideable: false,
              sortable: false,
              renderHeader: params => (
                <div
                  className="MuiDataGrid-columnHeaderTitle css-1gqmilo-MuiDataGrid-columnHeaderTitle"
                  data-testid="campaign-table-id-header"
                  data-mui-internal-clone-element="true"
                >
                  {params.colDef.headerName}
                </div>
              ),
            },
            {
              field: 'name',
              headerName: 'Campaign',
              minWidth: 350,
              renderCell: CampaignNameCellRenderer,
              hideable: false,
            },
            {
              field: 'status',
              headerName: 'Status',
              minWidth: 120,
              renderCell: CampaignStatusCellRenderer,
              filterable: false,
            },
            {
              field: 'action',
              headerName: '',
              width: 50,
              renderCell: CampaignActionMenuCellRenderer,
              sortable: false,
              filterable: false,
              disableColumnMenu: true,
              resizable: true,
            },
            {
              field: 'start_date',
              headerName: 'Start Date',
              minWidth: 120,
              renderCell: DateCellRenderer,
              filterable: false,
            },
            {
              field: 'end_date',
              headerName: 'End Date',
              minWidth: 120,
              // eslint-disable-next-line new-cap
              renderCell: params => DateCellRenderer(params, 'No end date'),
              filterable: false,
            },
            {
              field: 'daily_budget',
              headerName: 'Daily Budget',
              minWidth: 120,
              renderCell: CurrencyCellRenderer,
              sortable: false,
              align: 'right',
              filterable: false,
            },
            {
              field: 'stats_cache.total_spend',
              headerName: 'Spend',
              minWidth: 120,
              valueGetter: DotNotationValueGetter,
              renderCell: CurrencyCellRenderer,
              align: 'right',
              sortable: false,
            },
            {
              field: 'stats_cache.budget_remaining',
              headerName: 'Budget Remaining',
              minWidth: 150,
              valueGetter: DotNotationValueGetter,
              renderCell: CurrencyCellRenderer,
              align: 'right',
              sortable: false,
              filterable: false,
            },
            {
              field: 'stats_cache.action_count',
              headerName: 'Primary Outcome',
              align: 'right',
              minWidth: 150,
              flex: 1,
              valueGetter: DotNotationValueGetter,
              sortable: false,
              filterable: false,
            },
            {
              field: 'stats_cache.cost_per_visit',
              headerName: 'Cost per Visit',
              minWidth: 150,
              valueGetter: DotNotationValueGetter,
              renderCell: CurrencyCellRenderer,
              align: 'right',
              sortable: false,
              filterable: false,
            },
          ] as DataGridColumn[]
        }
      />
    </Stack>
  );
};

export default CampaignsTableV2;
