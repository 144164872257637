export const Status = {
    ACTIVE: 'ACTIVE',
    DRAFT: 'DRAFT',
    INACTIVE: 'INACTIVE',
    ARCHIVED: 'ARCHIVED'
} as const;

export type IEntityStatus = typeof Status[keyof typeof Status];

export const DefaultStatusKeys: IEntityStatus[] = [Status.ACTIVE, Status.DRAFT, Status.INACTIVE];

export const StatusDisplayNames: Record<IEntityStatus, string> = {
    [Status.ACTIVE]: 'Active',
    [Status.DRAFT]: 'Draft',
    [Status.INACTIVE]: 'Paused',
    [Status.ARCHIVED]: 'Archived'
} as const;
