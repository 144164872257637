import { type Keys } from '@local-types';
import { Type } from '@v2/components/campaign/Info';
import PropTypes from 'prop-types';
import { ReactNode } from 'react';

export const sectionInfoType = PropTypes.shape({
  type: PropTypes.oneOf(Object.keys(Type)),
  name: PropTypes.string,
  content: PropTypes.node,
});

export type SectionInfoProps = {
  infoBlocks: SectionInfoType[];
  sub?: string;
};

export type SectionInfoType = {
  type: Keys<typeof Type>;
  name: string;
  content: ReactNode;
  sub?: string;
};
