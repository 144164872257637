import React from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { Box, Chip, useTheme } from '@mui/material';
import { CreativeAdGroupV1 } from '@local-types';
import QCStatusCell from '@components/Creatives/QCStatusComponents';
import { Scopes } from '@constants/scopes';
import { usePermissions } from '@components/hooks/permissions';

export const CreativeStatusCellRenderer = (
  params: GridCellParams<CreativeAdGroupV1['creative_object'], boolean>,
) => {
  const theme = useTheme();
  const { hasPermission } = usePermissions();
  const showQCFunctionality = hasPermission([Scopes.CAN_VIEW_QC]);
  const { palette } = theme;

  if (params.value === null || params.value === undefined) {
    return null;
  }

  const status = params.value;
  const { qc, archived } = params.row;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '100%',
        gap: 2,
      }}
    >
      {archived ? (
        <Chip
          label="Archived"
          data-testid="archived-creative"
          sx={{
            color: '#77858c',
            background: '#EDF1F2',
            width: 90,
          }}
        />
      ) : status ? (
        <Chip
          label="Active"
          data-testid="active-creative"
          sx={{
            color: palette.green?.['6'],
            width: 90,
            background: palette.green?.['10'],
          }}
        />
      ) : (
        <Chip
          label="Inactive"
          data-testid="inactive-creative"
          sx={{
            width: 90,
            color: palette.blue?.['6'],
            background: palette.blue?.['10'],
          }}
        />
      )}
      {showQCFunctionality && !archived && (
        <QCStatusCell creative={params.row} qc={qc} />
      )}
    </Box>
  );
};
