import React from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import { Chip, useTheme } from '@mui/material';
import { Statuses } from '@components/containers/AdGroupsIndexPage/statuses';
import { AdGroup } from '@local-types/group';

export const AdGroupStatusCellRenderer = (
  params: GridCellParams<AdGroup, string | number>
) => {
  const theme = useTheme();
  if (params.value === null || params.value === undefined) {
    return null;
  }

  let status = params.value;
  if (typeof params.value !== 'number') {
    status = parseInt(params.value, 10);
  }

  if (params.row.temporary) {
    return (
      <Chip
        label="Temporary"
        data-testid="temporary-ad-group"
        sx={{
          width: '100%',
          color: theme.palette.grey['6'],
          background: theme.palette.grey['11'],
        }}
      />
    );
  }

  switch (status) {
    case Statuses.DRAFT:
      return (
        <Chip
          label="Draft"
          data-testid="draft-ad-group"
          sx={{
            width: '100%',
            color: theme.palette.blue['6'],
            background: theme.palette.blue['10'],
          }}
        />
      );
    case Statuses.ACTIVE:
      return (
        <Chip
          label="Active"
          data-testid="active-ad-group"
          sx={{
            width: '100%',
            color: theme.palette.green['6'],
            background: theme.palette.green['10'],
          }}
        />
      );
    case Statuses.PAUSED:
      return (
        <Chip
          label="Paused"
          data-testid="paused-ad-group"
          sx={{
            width: '100%',
            color: theme.palette.red['1'],
            background: theme.palette.red['5'],
          }}
        />
      );
    case Statuses.PENDING:
      return (
        <Chip
          label="Pending"
          data-testid="pending-ad-group"
          sx={{
            width: '100%',
            color: theme.palette.grey['6'],
            background: theme.palette.grey['11'],
          }}
        />
      );
    case Statuses.ARCHIVED:
      return (
        <Chip
          label="Archived"
          data-testid="archived-ad-group"
          sx={{
            width: '100%',
            color: '#77858c',
            background: '#EDF1F2',
          }}
        />
      );
  }

  return null;
};
