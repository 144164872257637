import { GridColDef } from '@mui/x-data-grid-pro';
import { get } from 'lodash';

export const DotNotationValueGetter = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  row: Record<string, any>,
  columnDefinition: GridColDef
) => {
  return get(row, columnDefinition.field, '-');
};
