import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
} from '@mui/material';

import AsyncButton from './AsyncButton';
import { GROUP_TYPE, useGroups } from './hooks/apis/groups';
import { AdGroup } from '@local-types/group';

const StyledDialogContent = styled(DialogContent)(
  ({ theme: { spacing } }) => ({
    '.MuiDialogTitle-root + &': {
      paddingTop: spacing(2),
      paddingBottom: spacing(2),
    },
  })
);

interface ManageBidProps {
  adGroup: AdGroup;
  campaignId: number;
  isOpen: boolean;
  onClose: () => void;
  setHasSaved: (hasSaved: boolean) => void;
}

const ManageBid = ({
  adGroup,
  campaignId,
  isOpen,
  onClose,
  setHasSaved,
}: ManageBidProps) => {
  const initialBid = () => parseFloat(adGroup.cpm).toFixed(2);
  const [bid, setBid] = useState(initialBid());

  const handleEditBid = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBid(event.target.value);
  };

  const { update, isLoading } = useGroups(GROUP_TYPE.AD_GROUP, campaignId);

  const handleSaveBid = async () => {
    if (bid && bid !== '') {
      const formattedBid = `${parseFloat(bid).toFixed(2)}`;
      if (formattedBid == adGroup.cpm) {
        return;
      }
    }

    try {
      await update({ id: adGroup.id, cpm: bid });
      setHasSaved(true);
      onClose();
    } catch (error) {
      console.error('Error in saving bid', error);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={isOpen}
      onClose={onClose}
      aria-labelledby="edit-bid-dialog"
    >
      <DialogTitle id="edit-bid-dialog">Edit Bid</DialogTitle>

      <StyledDialogContent>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item xs={6}>
            <DialogContentText>
              Enter Max CPM Bid for <strong>{adGroup.name}</strong>:
            </DialogContentText>
          </Grid>

          <Grid item xs={5}>
            <TextField
              data-testid="bid-input"
              autoFocus
              color="secondary"
              label="Max CPM Bid"
              size="small"
              onChange={handleEditBid}
              type="number"
              value={bid}
              variant="outlined"
              sx={{
                maxWidth: 200,
                textAlign: 'right',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </StyledDialogContent>

      <DialogActions>
        <Grid container alignItems="center" justifyContent="flex-end">
          <Grid container item justifyContent="flex-end" xs={2}>
            <Button sx={{ color: 'grey.main' }} onClick={onClose}>
              Cancel
            </Button>
          </Grid>

          <Grid item xs={3}>
            <AsyncButton
              data-testid="save-bid-button"
              isLoading={isLoading}
              onClick={handleSaveBid}
              textButton="Save"
              loadingButton="Saving..."
              size="small"
              type="small"
              variant="text"
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ManageBid;
