import React from 'react';
import {
  faArrowUpRightFromSquare,
  faPencilMechanical,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Stack } from '@mui/material';
import { OptionsAccordion } from '@v2/components/OptionsAccordion/OptionsAccordion';
import { Info } from '@v2/components/campaign/Info';
import {
  AdCapDurationLabelKeys,
  IncrementalOptionsLabelKeys,
} from '@v2/components/forms/AdvancedDeliveryOptionsForm/constants';
import { Button } from '@v2/components/ui/Button';
import { Controller } from 'react-hook-form';
import TypographyField from '../TypographyField';
import { fields } from '../constants';
import { useObjectivesSection } from '../hooks/useObjectivesSection';
import { ObjectiveSectionProps } from './types';
import { type AccordionOption } from '@v2/components/OptionsAccordion/types';

/**
 * TODO: fix the type of the props related to prop-types
 */
export const ObjectiveSection = ({
  // eslint-disable-next-line react/prop-types
  withAdvancedDeliveryOptions,
  // eslint-disable-next-line react/prop-types
  onAdvancedCampaignDeliveryOptionsClick,
}: ObjectiveSectionProps) => {
  const {
    form: {
      control,
      formState: { errors },
    },
    Copy,
    trackingIsSetUp,
    objectivesOptions,
    isIncremental,
    isExperiment,
    isCapAds,
    experimentType,
    experimentPlan,
    freqCaps,
    AdvancedDeliveryOptionsFormCopy,
    onObjectivesChange,
  } = useObjectivesSection();

  return (
    <Stack gap={4}>
      <Controller
        control={control}
        name={fields.objective.path}
        render={({ field }) => (
          <OptionsAccordion
            {...field}
            name="campaign-objective-tracking-on"
            headerTitle={Copy.campaignObjectiveHeader}
            trackingIsSetUp={trackingIsSetUp}
            options={objectivesOptions}
            onChange={(_: unknown, option: AccordionOption) => {
              field.onChange(option.value);
              onObjectivesChange();
            }}
          />
        )}
      />
      {!!withAdvancedDeliveryOptions && (
        <Box>
          {!!errors[fields.advancedOptions.path] && (
            <Info type="error">
              {errors?.[fields.advancedOptions.path]?.message as string}
            </Info>
          )}
          <Button
            label={Copy.advancedOptionsLabel as string}
            data-testid="advanced-options-button"
            icon={faArrowUpRightFromSquare}
            onClick={onAdvancedCampaignDeliveryOptionsClick}
            size="small"
            variant="text"
            sx={{ ml: -1 }}
          />

          {(isIncremental || isExperiment || isCapAds) && (
            <Box component="ul" sx={{ my: 1 }}>
              {isIncremental && (
                <li>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <TypographyField
                      variant="text"
                      control={control}
                      name={`${fields.advancedOptions.path}.experimentType`}
                    >
                      <strong>{Copy.incrementalityLabel}</strong>{' '}
                      {
                        IncrementalOptionsLabelKeys[
                          experimentType as keyof typeof IncrementalOptionsLabelKeys
                        ]
                      }
                    </TypographyField>

                    <FontAwesomeIcon icon={faPencilMechanical} />
                  </Stack>
                </li>
              )}

              {isExperiment && (
                <li>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <TypographyField
                      variant="text"
                      control={control}
                      name={`${fields.advancedOptions.path}.experimentPlan`}
                    >
                      <strong>{Copy.experimentLabel}</strong>{' '}
                      {AdvancedDeliveryOptionsFormCopy[experimentPlan]}
                    </TypographyField>

                    <FontAwesomeIcon icon={faPencilMechanical} />
                  </Stack>
                </li>
              )}

              {isCapAds &&
                freqCaps?.map(
                  (
                    {
                      impressions,
                      duration,
                      fctype,
                    }: {
                      impressions: number;
                      duration: number;
                      fctype: keyof typeof AdCapDurationLabelKeys;
                    },
                    index: number
                  ) => (
                    <li key={index}>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <TypographyField
                          variant="text"
                          control={control}
                          name={`${fields.advancedOptions.path}.freqCaps`}
                        >
                          <strong>{Copy.frequencyCapLabel}</strong>{' '}
                          {impressions} ads every {duration}{' '}
                          {AdCapDurationLabelKeys[fctype]}
                        </TypographyField>

                        <FontAwesomeIcon icon={faPencilMechanical} />
                      </Stack>
                    </li>
                  )
                )}
            </Box>
          )}
        </Box>
      )}
    </Stack>
  );
};
