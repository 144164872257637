import { values } from 'lodash';
import { customInventoryTabValues } from './general';

export const inventoryCategoryName = {
  [customInventoryTabValues.appName]: 'App Names',
  [customInventoryTabValues.appBundleList]: 'App Bundle Lists',
  [customInventoryTabValues.dealId]: 'Deal IDs',
} as const;

export const customInventoryAccessibleTypes = values(inventoryCategoryName);
