import { isUndefined } from 'lodash';

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const isEmptyPixels = pixelsString => {
  try {
    const pixels = JSON.parse(pixelsString);
    return !pixels.length;
  } catch {
    return true;
  }
};

export const sort = (array, order, orderBy) => {
  const data = [...array];
  const comparator =
    order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);

  data.sort((a, b) => {
    const res = comparator(a, b);

    if (res !== 0) {
      return res;
    }
    return a[1] - b[1];
  });

  return data;
};

export const isFile = item => !isUndefined(item?.progress);

export const isUploading = item => isFile(item) && item.progress < 100;
export const isProcessing = item => item.processing;
