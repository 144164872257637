import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  FormControlLabel,
  Radio,
  Stack,
  styled,
} from '@mui/material';

export const WrapperStyled = styled(Stack, {
  shouldForwardProp: prop => prop !== 'expanded',
})<{
  expanded?: boolean;
}>(({ theme, expanded }) => ({
  backgroundColor: theme.palette.common.offWhite,
  border: `1px solid ${theme.palette.divider}`,
  margin: theme.spacing(1, 0),
  boxShadow:
    '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)',

  '&:first-of-type': {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,

    '.options-accordion-alert-box': {
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
    },
  },
  '&:last-of-type': {
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
  '&:not(:first-of-type)': {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  '&:not(:last-of-type)': {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },

  ...(expanded && {
    backgroundColor: theme.palette.blue[11],
  }),
}));

export const AccordionStyled = styled(Accordion)(({ theme, expanded }) => ({
  boxShadow: 'none',

  maxWidth: '100%',
  '&.MuiAccordion-root:before': {
    display: 'none',
  },
  '& .MuiButtonBase-root.MuiAccordionSummary-root': {
    cursor: expanded ? 'default' : 'pointer',
    padding: 0,
  },
  '& .MuiAccordionSummary-content': {
    maxWidth: '100%',
    margin: 0,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  backgroundColor: expanded
    ? theme.palette.blue[11]
    : theme.palette.common.offWhite,
  transition: 'background-color 0.3s',

  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: 0,
  },
}));

export const AlertBoxStyled = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  flexDirection: 'row',
  backgroundColor: theme.palette.chart[6],
  boxSizing: 'border-box',
  clear: 'both',
  color: theme.palette.text.primary,
  padding: theme.spacing(1),
  width: '100%',
  paddingLeft: theme.spacing(2.5),
  paddingRight: theme.spacing(2.5),
}));

export const AlertIconStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.grey[3],
  marginRight: '12px',
}));

export const FormControlLabelStyled = styled(FormControlLabel, {
  shouldForwardProp: prop => prop !== 'expanded',
})<{
  expanded?: boolean;
}>(({ theme, expanded }) => ({
  width: '100%',
  margin: 0,
  padding: theme.spacing(1.5, 5, 1.5, 1),
  cursor: expanded ? 'default' : 'pointer',
}));

export const RadioStyled = styled(Radio)(({ theme }) => ({
  color: theme.palette.blue[6],
  '&.Mui-checked': {
    color: theme.palette.blue[6],
  },
}));

export const InfoIconStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.grey[8],
  margin: 0,
  padding: 0,
}));

// Styled faCircleInfo for open accordion
export const CheckIconStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2.25),
}));

// Styled faLockAlt for locked accordion
export const LockIconStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.grey[3],
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2.25),
}));
