import React from 'react';
import { Themes } from '../constants/themes';
import {
  Advertiser,
  AdvertiserEvent,
  BidStrategy,
  BidStrategyEvent,
  CampaignObjectiveTypes,
  Values,
} from '@local-types';

const AdvertiserContext = React.createContext<{
  id: number | null;
  name: string;
  theme: Values<typeof Themes>;
  cost_model: string | null;
  primary_org: string;
  updateAdvertiser: (data: Partial<Advertiser>) => void | null;
  url: string;
  uses_looker: boolean;
  looker_validated: boolean;
  path_to_purchase_validated: boolean;
  bidstrategyevent_set: BidStrategyEvent[];
  has_incrementality: boolean;
  has_sequential_events: boolean;
  has_spike_lift: boolean;
  advertiserevent_set: AdvertiserEvent[];
  campaign_objective_types: CampaignObjectiveTypes;
  tvsciq: string;
  bidstrategy_set: BidStrategy[];
  looker_experience: string;
  billing_account_is_valid: boolean;
}>({
  id: null,
  name: '',
  theme: Themes.NBCU,
  cost_model: null,
  primary_org: '',
  updateAdvertiser: () => null,
  url: '',
  uses_looker: false,
  looker_validated: false,
  path_to_purchase_validated: false,
  bidstrategyevent_set: [],
  has_incrementality: false,
  has_sequential_events: false,
  has_spike_lift: false,
  advertiserevent_set: [],
  campaign_objective_types: {},
  tvsciq: '',
  billing_account_is_valid: false,
  bidstrategy_set: [],
  looker_experience: '',
});

AdvertiserContext.displayName = 'AdvertiserContext';

export default AdvertiserContext;
