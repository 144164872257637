import { ReactNode } from 'react';
import { GridCellParams } from '@mui/x-data-grid-pro';
import moment from 'moment-timezone';

// Renders a string formatted as a date
export const DateCellRenderer = (
  params: Partial<GridCellParams>,
  fallbackText?: ReactNode
) => {
  if (!params.value || typeof params.value !== 'string') {
    return fallbackText ?? null;
  }

  const date = moment(params.value);
  if (!date.isValid()) {
    return fallbackText ?? null;
  }
  return date.format('MM/DD/YYYY');
};
